import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes, IFeatures, SlotIdAndPosition } from '../../global.d';
import { setDelayFeatureNum, setDelayWinAnimation, setGameMode, setIsForceStop, setIsTurboSpin } from '../../gql/cache';
import { isFreeSpinsMode, isScatter } from '../../utils';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  REELS_AMOUNT,
  SLOTS_PER_REEL_AMOUNT,
  THROW_TOMATO_DELAY_TIME_LONG,
  THROW_TOMATO_DELAY_TIME_SHORT,
  eventManager,
} from '../config';

import ThrowTomato from './throwTomato';

class TomatoContainer extends ViewContainer {
  private tomato: ThrowTomato[] = [];
  private tomatoAmount = 0;
  private scatterCnt = 0;
  private sound = false;

  constructor() {
    super();

    this.position.set(0, 0);

    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.tomato[i] = new ThrowTomato();
      this.addChild(this.tomato[i]!);
    }

    eventManager.addListener(EventTypes.TOMATO, this.tomatoCreate.bind(this));
    eventManager.addListener(EventTypes.TOMATO_DELETE, this.tomatoDelete.bind(this));
  }

  private tomatoCreate(features: IFeatures): void {
    if (features.gameRoundStore.tomatoFeatureStore === undefined) return;
    this.sound = Object.entries(features.gameRoundStore.tomatoFeatureStore.mappedIcons).length > 0 ? true : false;
    this.scatterCnt = 0;
    const slotAndPos: SlotIdAndPosition[] = [];
    if (isFreeSpinsMode(setGameMode())) return;

    // TOMATO
    Object.entries(features.gameRoundStore.tomatoFeatureStore.mappedIcons).forEach(([index, value]) => {
      const pos = Number(index);
      slotAndPos.push({
        slot: value.resultId,
        position: pos,
      });
    });

    if (slotAndPos.length > 0 && !setIsTurboSpin()) {
      setDelayFeatureNum(slotAndPos.length);
    } else if (slotAndPos.length > 0 && setIsTurboSpin()) {
      setDelayFeatureNum(1);
    }
    this.tomatoAmount = slotAndPos.length;
    this.throwTomato(slotAndPos);
  }

  private throwTomato(slotAndPos: SlotIdAndPosition[]) {
    const sortedSlotAndPos = this.sortPositions(slotAndPos);
    for (let i = 0; i < this.tomato.length; i++) {
      if (i < sortedSlotAndPos.length) {
        const delay = Tween.createDelayAnimation(setIsTurboSpin() ? 0 : i * 250 + 1000);
        delay.addOnComplete(() => {
          this.tomato[i]!.start(sortedSlotAndPos[i]!.position, sortedSlotAndPos[i]!.slot);
        });
        delay.start();
        eventManager.once(EventTypes.SKIP_SPIN_ANIMATION, () => {
          delay.removeOnComplete(() => {});
        });
      }
    }
  }

  private tomatoDelete(): void {
    if (isFreeSpinsMode(setGameMode())) return;
    if (setIsForceStop()) {
      if (this.sound) {
        AudioApi.play({ type: ISongs.Tomato2 });
      }
      return;
    }
    let scatterAmount = 0;
    let delayTime = 0;
    for (let i = 0; i < this.tomatoAmount; i++) {
      if (i != 0) {
        if (scatterAmount >= 2 && scatterAmount < 4) {
          delayTime += THROW_TOMATO_DELAY_TIME_LONG;
        } else {
          delayTime += THROW_TOMATO_DELAY_TIME_SHORT;
        }
      }

      if (isScatter(this.tomato[i]!.slot!)) scatterAmount++;
      const delay = Tween.createDelayAnimation(delayTime);
      delay.addOnComplete(() => {
        let sound = undefined;
        const nearmiss = this.scatterCnt >= 2 ? true : false;
        if (this.tomato[i]!.slot === SlotId.SC1) {
          this.scatterCnt++;
          sound =
            this.scatterCnt === 1
              ? ISongs.Scatter_01
              : this.scatterCnt === 2
              ? ISongs.Scatter_02
              : this.scatterCnt === 3
              ? ISongs.Scatter_03
              : this.scatterCnt === 4
              ? ISongs.Scatter_04
              : undefined;
        }
        this.tomato[i]!.end(sound, nearmiss);
      });

      delay.start();

      eventManager.once(EventTypes.SET_IS_SLOTS_STOPPED, () => {
        delay.removeOnComplete(() => {});
        eventManager.emit(EventTypes.SET_ALL_MULTIPLIER, true);
      });
    }
    if (this.tomatoAmount > 0) delayTime += 1100;
    setDelayWinAnimation(delayTime > setDelayWinAnimation() ? delayTime : setDelayWinAnimation());
    this.tomatoAmount = 0;
  }

  private sortPositions(slotAndPos: SlotIdAndPosition[]): SlotIdAndPosition[] {
    const sortedPositions: SlotIdAndPosition[] = [];
    const copyPositions = slotAndPos;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      for (let index = 0; index < copyPositions.length; index++) {
        if (copyPositions[index]!.position % REELS_AMOUNT == i) {
          sortedPositions.push(copyPositions.splice(index, 1)[0]!);
          i--;
        }
      }
    }
    return sortedPositions;
  }
}

export default TomatoContainer;

import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.I]: ResourceTypes.symbolI,
  [SlotId.J]: ResourceTypes.symbolJ,
  [SlotId.O]: ResourceTypes.symbolWayArrowRight,
  [SlotId.P]: ResourceTypes.symbolWayArrowLeft,
  [SlotId.Q]: ResourceTypes.symbolWayArrowUp,
  [SlotId.R]: ResourceTypes.symbolWayArrowDown,
  [SlotId.WL]: ResourceTypes.symbolWild,
  [SlotId.SC1]: ResourceTypes.symbolScatter,
  [SlotId.v]: ResourceTypes.symbolBlank,
};
export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.symbolABlur,
  [SlotId.B]: ResourceTypes.symbolBBlur,
  [SlotId.C]: ResourceTypes.symbolCBlur,
  [SlotId.D]: ResourceTypes.symbolDBlur,
  [SlotId.E]: ResourceTypes.symbolEBlur,
  [SlotId.F]: ResourceTypes.symbolFBlur,
  [SlotId.G]: ResourceTypes.symbolGBlur,
  [SlotId.H]: ResourceTypes.symbolHBlur,
  [SlotId.I]: ResourceTypes.symbolIBlur,
  [SlotId.J]: ResourceTypes.symbolJBlur,
  [SlotId.O]: ResourceTypes.symbolWayArrowRightBlur,
  [SlotId.P]: ResourceTypes.symbolWayArrowLeftBlur,
  [SlotId.Q]: ResourceTypes.symbolWayArrowUpBlur,
  [SlotId.R]: ResourceTypes.symbolWayArrowDownBlur,
  [SlotId.WL]: ResourceTypes.symbolWild,
  [SlotId.SC1]: ResourceTypes.symbolScatter,
  [SlotId.v]: ResourceTypes.symbolBlank,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.O]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_way_announce_right',
  },
  [SlotId.P]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_way_announce_left',
  },
  [SlotId.Q]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_way_announce_up',
  },
  [SlotId.R]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_way_announce_down',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_wild_announce',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_scatter_announce',
  },
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_a_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_b_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_c_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_d_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_e_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_f_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_g_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_h_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_i_win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_j_win',
  },
  [SlotId.O]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_scatter_win',
  },
  [SlotId.P]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_scatter_win',
  },
  [SlotId.Q]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_scatter_win',
  },
  [SlotId.R]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_scatter_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_wild_win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_scatter_win',
  },
  [SlotId.v]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_scatter_win',
  },
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.logo,
  ResourceTypes.titlelogo,
  ResourceTypes.infoPaylines,
  ResourceTypes.infoPaylines2,
  ResourceTypes.infoPaylines3,
  ResourceTypes.infoPaylines4,

  ResourceTypes.intro1,
  ResourceTypes.intro2,
  ResourceTypes.intro3,
  ResourceTypes.introBg,

  ResourceTypes.slotBg,
  ResourceTypes.slotBgF,
  ResourceTypes.slotBgF2,

  ResourceTypes.reelback,
  ResourceTypes.reelframe,

  ResourceTypes.symbolA,
  ResourceTypes.symbolABlur,
  ResourceTypes.symbolB,
  ResourceTypes.symbolBBlur,
  ResourceTypes.symbolC,
  ResourceTypes.symbolCBlur,
  ResourceTypes.symbolD,
  ResourceTypes.symbolDBlur,
  ResourceTypes.symbolE,
  ResourceTypes.symbolEBlur,
  ResourceTypes.symbolF,
  ResourceTypes.symbolFBlur,
  ResourceTypes.symbolG,
  ResourceTypes.symbolGBlur,
  ResourceTypes.symbolH,
  ResourceTypes.symbolHBlur,
  ResourceTypes.symbolI,
  ResourceTypes.symbolIBlur,
  ResourceTypes.symbolJ,
  ResourceTypes.symbolJBlur,

  ResourceTypes.symbolBlank,
  ResourceTypes.symbolScatter,
  ResourceTypes.symbolWayArrowDown,
  ResourceTypes.symbolWayArrowDownBlur,
  ResourceTypes.symbolWayArrowLeft,
  ResourceTypes.symbolWayArrowLeftBlur,
  ResourceTypes.symbolWayArrowRight,
  ResourceTypes.symbolWayArrowRightBlur,
  ResourceTypes.symbolWayArrowUp,
  ResourceTypes.symbolWayArrowUpBlur,
  ResourceTypes.symbolWild,
  ResourceTypes.lt,
  ResourceTypes.ts,

  ResourceTypes.popupBg,

  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureInput,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePopup,
  ResourceTypes.buyFeaturePopupConfirm,
  ResourceTypes.buyLtfBtn,
  ResourceTypes.buyTsfBtn,
  ResourceTypes.featureBanner,
  ResourceTypes.winBanner,

  ResourceTypes.backdrop,

  ResourceTypes.ltfEffect1,
  ResourceTypes.ltfEffect2,
  ResourceTypes.ltfEffect3,
  ResourceTypes.ltfEffectResult,
  ResourceTypes.ltfLogo,

  ResourceTypes.tomatospinFeatureWildIndicator,
  ResourceTypes.tomatospinFeatureWildIndicatorTomato,

  ResourceTypes.wayIndicatorBase,
  ResourceTypes.panel,
  ResourceTypes.panelRight,
  ResourceTypes.minipaytable,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'nearmiss',
      url: generateTexturePath('/animations', 'nearmiss/nearmiss.json', isMobile),
    },
    {
      name: 'all_symbols',
      url: generateTexturePath('/animations', 'symbol_all/symbol_all.json', isMobile),
    },
    {
      name: 'boy',
      url: generateTexturePath('/animations', 'chara_a/chara_a.json', isMobile),
    },
    {
      name: 'girl',
      url: generateTexturePath('/animations', 'chara_b/chara_b.json', isMobile),
    },
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
    {
      name: 'payline',
      url: generateTexturePath('/animations', 'payline_effect/payline_effect.json', isMobile),
    },
    {
      name: 'truck',
      url: generateTexturePath('/animations', 'truck_feature/truck_feature.json', isMobile),
    },
    {
      name: 'way',
      url: generateTexturePath('/animations', 'way_indicator/way_indicator.json', isMobile),
    },
    {
      name: 'FTtomato',
      url: generateTexturePath('/animations', 'tomato_feature/tomato_feature.json', isMobile),
    },
    {
      name: 'ltf_chara',
      url: generateTexturePath('/animations', 'ltf_character/ltf_character.json', isMobile),
    },
    {
      name: 'ltf_effect',
      url: generateTexturePath('/animations', 'ltf_effect/tomato_feature.json', isMobile),
    },
    {
      name: 'ltf_gun',
      url: generateTexturePath('/animations', 'ltf_gun/ltf_gun.json', isMobile),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];

export enum ResourceTypes {
  logo = 'logo',
  ltfEffect1 = 'ltfEffect1',
  ltfEffect2 = 'ltfEffect2',
  ltfEffect3 = 'ltfEffect3',
  ltfEffectResult = 'ltfEffectResult',
  reelback = 'reelback',
  reelframe = 'reelframe',
  slotBg = 'slotBg',
  slotBgF = 'slotBgF',
  slotBgF2 = 'slotBgF2',
  symbolA = 'symbolA',
  symbolABlur = 'symbolABlur',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolBlank = 'symbolBlank',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolG = 'symbolG',
  symbolGBlur = 'symbolGBlur',
  symbolH = 'symbolH',
  symbolHBlur = 'symbolHBlur',
  symbolI = 'symbolI',
  symbolIBlur = 'symbolIBlur',
  symbolJ = 'symbolJ',
  symbolJBlur = 'symbolJBlur',
  symbolScatter = 'symbolScatter',
  symbolWayArrowDown = 'symbolWayArrowDown',
  symbolWayArrowDownBlur = 'symbolWayArrowDownBlur',
  symbolWayArrowLeft = 'symbolWayArrowLeft',
  symbolWayArrowLeftBlur = 'symbolWayArrowLeftBlur',
  symbolWayArrowRight = 'symbolWayArrowRight',
  symbolWayArrowRightBlur = 'symbolWayArrowRightBlur',
  symbolWayArrowUp = 'symbolWayArrowUp',
  symbolWayArrowUpBlur = 'symbolWayArrowUpBlur',
  symbolWild = 'symbolWild',
  lt = 'lt',
  ts = 'ts',
  backdrop = 'backdrop',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buyLtfBtn = 'buyLtfBtn',
  buyTsfBtn = 'buyTsfBtn',
  featureBanner = 'featureBanner',
  infoPaylines = 'infoPaylines',
  infoPaylines2 = 'infoPaylines2',
  infoPaylines3 = 'infoPaylines3',
  infoPaylines4 = 'infoPaylines4',
  intro1 = 'intro1',
  intro2 = 'intro2',
  intro3 = 'intro3',
  introBg = 'introBg',
  ltfBtn = 'ltfBtn',
  ltfLogo = 'ltfLogo',
  minipaytable = 'minipaytable',
  panel = 'panel',
  panelRight = 'panelRight',
  popupBg = 'popupBg',
  symbolMystery = 'symbolMystery',
  symbolWildTomato = 'symbolWildTomato',
  titlelogo = 'titlelogo',
  tomatospinFeatureWildIndicator = 'tomatospinFeatureWildIndicator',
  tomatospinFeatureWildIndicatorTomato = 'tomatospinFeatureWildIndicatorTomato',
  wayIndicatorBase = 'wayIndicatorBase',
  winBanner = 'winBanner',
}

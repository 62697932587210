import { EventTypes, GameMode } from '../../global.d';
import { setLatomatinaFeature } from '../../gql/cache';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  LATOMATINA_SMALL_MARGIN,
  LATOMATINA_SMALL_MAX,
  LATOMATINA_SMALL_SPEED,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SlotMachineState,
  eventManager,
} from '../config';

import bigTomato from './bigTomato';
import smallTomato from './smallTomato';

class TomatinaContainer extends ViewContainer {
  private tomato: smallTomato[] = [];
  private bigTomato: bigTomato | undefined;
  private spin = false;
  private skip = false;
  private bigTomatoDelay: Animation | null = null;

  constructor() {
    super();

    this.position.set(-SLOTS_CONTAINER_WIDTH / 2, -SLOTS_CONTAINER_HEIGHT / 2);

    eventManager.once(EventTypes.TOMATINA, this.startSmall.bind(this));
    eventManager.once(EventTypes.TOMATINA_BIG, this.startBig.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.LATOMATINA_START, this.onResult.bind(this));
  }

  private onChangeMode(settings: { mode: GameMode }) {
    if (settings.mode === GameMode.FREE_SPIN_LA_TOMATINA) {
      this.spin = false;
      this.skip = false;
    }
  }

  private onResult(): void {
    this.spin = true;
    if (this.skip && this.bigTomatoDelay != null) {
      this.bigTomatoDelay.onComplete();
      this.bigTomatoDelay.skip();
    }
  }

  private startSmall() {
    this.tomato = [];
    const multipliers = setLatomatinaFeature().multipliers;
    for (let i = 0; i < multipliers.length; i++) {
      const randomPositionX =
        LATOMATINA_SMALL_MARGIN + Math.floor(Math.random() * (SLOTS_CONTAINER_WIDTH - LATOMATINA_SMALL_MARGIN * 2));
      const randomPositionY =
        LATOMATINA_SMALL_MARGIN + Math.floor(Math.random() * (SLOTS_CONTAINER_HEIGHT - LATOMATINA_SMALL_MARGIN * 2));

      const smallTomatoDelay = Tween.createDelayAnimation(i * LATOMATINA_SMALL_SPEED);
      smallTomatoDelay.addOnComplete(() => {
        this.tomato[i] = new smallTomato();
        this.addChild(this.tomato[i]!);
        this.tomato[i]!.start('x' + multipliers[i], randomPositionX, randomPositionY);

        if (this.tomato[i - LATOMATINA_SMALL_MAX]) {
          this.tomato[i - LATOMATINA_SMALL_MAX]!.remove();
          this.removeChild(this.tomato[i - LATOMATINA_SMALL_MAX]!);
        }
      });
      smallTomatoDelay.start();
    }
    this.bigTomatoDelay = Tween.createDelayAnimation(multipliers.length * LATOMATINA_SMALL_SPEED + 1500);

    this.bigTomatoDelay.addOnComplete(() => {
      this.tomato = [];
      eventManager.emit(EventTypes.TOMATINA_BIG);
    });
    this.bigTomatoDelay.start();
  }

  private startBig() {
    const multiplier = setLatomatinaFeature().sumMultiplier;
    if (multiplier === 0) {
      eventManager.once(EventTypes.TOMATINA_BIG, this.startBig.bind(this));
      return;
    }
    this.bigTomato = new bigTomato();
    this.addChild(this.bigTomato);
    this.bigTomato.setVisible(false);
    const bigTomatoStartDelay = Tween.createDelayAnimation(800);
    bigTomatoStartDelay.addOnComplete(() => {
      this.bigTomato!.start('x' + multiplier);
      this.bigTomato!.setVisible(true);
      const bigTomatoEndDelay = Tween.createDelayAnimation(2500);

      bigTomatoEndDelay.addOnComplete(() => {
        eventManager.emit(EventTypes.SET_STATE, SlotMachineState.WINNING);
      });
      bigTomatoEndDelay.start();
    });
    bigTomatoStartDelay.start();
  }

  public remove() {
    this.bigTomato!.remove();
    this.removeChild(this.bigTomato!);
  }
}

export default TomatinaContainer;

export const BACKGROUND_SIZE_WIDTH = 1920;
export const BACKGROUND_SIZE_HEIGHT = 1080;

export const HEIGHT_BASE = 850;

export type BgSkin = 'default' | 'feature';

export const AMBIENT_ANIMATION_NAME = 'ambient';

export const AMBIENT_ANIMATION_STATE1 = 'ambient_fs_state1';
export const AMBIENT_ANIMATION_STATE2 = 'ambient_fs_state2';
export const AMBIENT_ANIMATION_STATE3 = 'ambient_fs_state3';

export const STATE1_TIME_SCALE = 1;
export const STATE2_TIME_SCALE = 1.5;
export const STATE3_TIME_SCALE = 2;

export const BG_FREE_SPIN_LANDSCAPE_SPRITE_Y = 400;
export const BG_LANDSCAPE_SPINE_Y = -465;

export const BG_FREE_SPIN_PORTRAIT_SPRITE_Y = 20;
export const BG_PORTRAIT_SPINE_Y = -800;

import { Colors } from '../../config';
import { EventTypes, GameMode, TotalWinBannerMode } from '../../global.d';
import { SetIsCountUp, setBrokenBuyFeature, setBrokenGame, setCurrentBonus } from '../../gql/cache';
import { calcPercentage, canPressSpin, getGameModeByBonusId } from '../../utils';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class SpinBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isSlotStopped = false;

  private isFreeSpinsWin = false;

  private gameMode = GameMode.REGULAR;

  private isOpenedMessageBanner: boolean;

  private isInTransition: boolean;

  private isBuyFeaturePopup: boolean;

  private isTotalWinMessageBanner: TotalWinBannerMode;

  private isFadeOut = false;

  private isTomatina = false;

  private isPhoenix = false;

  constructor() {
    super('spin');
    this.interactive = !this.isDisabled;
    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;
    this.isOpenedMessageBanner = false;
    this.isInTransition = false;
    this.isBuyFeaturePopup = false;
    this.isTotalWinMessageBanner = TotalWinBannerMode.NON;
    this.btn.tint = Colors.GAME_COLOR;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      this.isSlotStopped = isSlotStopped;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREE_SPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_OPENED_MESSAGE_BANNER, (isOpenedMessageBanner: boolean) => {
      this.isOpenedMessageBanner = isOpenedMessageBanner;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_TOTAL_WIN_MESSAGE_BANNER, (isTotalWinMessageBanner: TotalWinBannerMode) => {
      this.isTotalWinMessageBanner = isTotalWinMessageBanner;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_IN_TRANSITION, (isInTransition: boolean) => {
      this.isInTransition = isInTransition;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, (isBuyFeaturePopup: boolean) => {
      this.isBuyFeaturePopup = isBuyFeaturePopup;
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, (isBuyFeaturePopup: boolean) => {
      this.isBuyFeaturePopup = isBuyFeaturePopup;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FADEOUT, (isFadeOut: boolean) => {
      this.isFadeOut = isFadeOut;
      this.handleDisable();
    });
    eventManager.on(EventTypes.UI_VISIBLE, () => {
      this.visible = !this.visible;
    });

    eventManager.on(EventTypes.SET_IS_COUNT_UP, (_isCountUp: boolean) => {
      this.handleDisable();
    });

    eventManager.on(EventTypes.RESIZE_GAME_CONTAINER, this.handlePosition.bind(this));

    eventManager.on(EventTypes.SET_BROKEN_BUY_FEATURE, (_isBroken: boolean) => {
      this.handleDisable();
    });

    eventManager.on(EventTypes.SET_IS_TOMATINA, (isTomatina: boolean) => {
      this.isTomatina = isTomatina;
      this.handleDisable();
    });

    eventManager.on(EventTypes.SET_IS_PHOENIX, (isPhoenix: boolean) => {
      this.isPhoenix = isPhoenix;
      this.handleDisable();
    });
  };

  private handleUpdateIntent = (): void => {
    if (this.isAutoSpins) {
      this.handleDisable();
    } else {
      this.updateIntent(this.isSlotBusy ? 'skip' : 'spin');
    }
  };

  private handleDisable = (): void => {
    const isDisable = !canPressSpin({
      gameMode: this.gameMode,
      isFreeSpinsWin: this.isFreeSpinsWin,
      isSlotBusy: this.isSlotBusy,
      isSlotStopped: this.isSlotStopped,
      isSpinInProgress: this.isSpinInProgress,
      isInTransition: this.isInTransition,
      isCountUp: SetIsCountUp(),
      isBuyFeaturePopupOpened: this.isBuyFeaturePopup,
      isAutoPlay: this.isAutoSpins,
      isFadeOut: this.isFadeOut,
      isBrokenBuyFeature: setBrokenBuyFeature(),
      isTomatina: this.isTomatina,
      isPhoenix: this.isPhoenix,
    });
    this.setDisable(isDisable);
  };

  private handleClick = (): void => {
    if (setBrokenBuyFeature()) return;
    if (!this.isDisabled) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
      eventManager.emit(EventTypes.TOGGLE_SPIN);
    }
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0.5);
    let size = calcPercentage(this.applicationSize.width, 17);
    const gap = calcPercentage(this.applicationSize.width, 0.95);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height / 2;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 17);
      this.btn.anchor.set(0.5, 1);
      x = this.applicationSize.width / 2;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default SpinBtn;

import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

class ltfLogo extends PIXI.Sprite {
  private Logo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.ltfLogo));
  constructor() {
    super();

    this.Logo.anchor.set(0.5);
    this.Logo.position.set(SLOTS_CONTAINER_WIDTH / 2 + 35, -235);
    this.addChild(this.Logo);
    this.visible = false;

    eventManager.addListener(EventTypes.CHANGE_MODE, this.setVisible.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.setVisible.bind(this));
  }

  private setVisible(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_SPIN_LA_TOMATINA) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
}

export default ltfLogo;

import { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const bigTomatoTextStyle: Partial<ITextStyle> = {
  fontSize: 160,
  fontFamily: font,
  fill: '#ffffff',
  stroke: '#111111',
  strokeThickness: 7,
};

export const smallTomatoTextStyle: Partial<ITextStyle> = {
  fontSize: 60,
  fontFamily: font,
  fill: '#ffffff',
  stroke: '#111111',
  strokeThickness: 7,
};

import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { laTomatinaTotalTextStyle } from '../config';

import { bigTomatoTextStyle, smallTomatoTextStyle } from './textStyle';

class ltfEffect extends ViewContainer {
  private spine: SpineAnimation | undefined;
  private sprite = new PIXI.Sprite();
  private text = new PIXI.Text();
  private isSmall: boolean;
  private laTomatinaText: PIXI.Text = new PIXI.Text(i18n.t('laTomatinaTotal'), laTomatinaTotalTextStyle);

  constructor(multiplier: string, alpha: number, isSmall: boolean) {
    super();
    this.isSmall = isSmall;

    this.init(multiplier, alpha, isSmall);
  }

  private init(multiplier: string, alpha: number, isSmall: boolean) {
    this.isSmall = isSmall;
    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources['ltf_effect']!.spineData);
    if (isSmall) {
      this.randomAnimation();
      this.text.style = smallTomatoTextStyle;
      this.sprite.alpha = alpha;
    } else {
      this.resultAnimation();
      this.text.style = bigTomatoTextStyle;
    }
    this.laTomatinaText.visible = false;

    this.sprite.anchor.set(0.5);
    this.sprite.visible = false;
    this.text.text = multiplier;
    this.text.anchor.set(0.5);
    this.text.visible = false;
    if (!isSmall) {
      this.laTomatinaText.position.y = -135;
      this.laTomatinaText.anchor.set(0.5);
    }
  }

  public start() {
    this.spine!.addOnStart(() => {
      this.addChild(this.sprite);
      this.addChild(this.spine!.getSpine());
      this.addChild(this.text);

      if (this.isSmall) {
        const delay = Tween.createDelayAnimation(400);
        delay.addOnComplete(() => {
          AudioApi.stop({ type: ISongs.LTF_tomatoMP });
          AudioApi.play({ type: ISongs.LTF_tomatoMP });
          this.text.visible = true;
        });
        delay.start();
      }
    });

    this.spine?.addOnComplete(() => {
      if (this.isSmall) {
        setTimeout(() => {
          this.sprite.visible = true;
        });
      } else if (!this.isSmall) {
        AudioApi.play({ type: ISongs.LTF_end });
        setTimeout(() => {
          this.laTomatinaText.visible = true;
          this.addChild(this.laTomatinaText);
          this.sprite.visible = true;
          this.text.visible = true;
        });
      }
    });

    this.spine!.start();
  }

  private randomAnimation() {
    const random = Math.floor(Math.random() * 3);
    switch (random) {
      case 0:
        this.spine!.setAnimation('ltf_effect1', false);
        const effectSprite1 = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.ltfEffect1));
        this.sprite.texture = effectSprite1.texture;
        break;

      case 1:
        this.spine!.setAnimation('ltf_effect2', false);
        const effectSprite2 = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.ltfEffect2));
        this.sprite.texture = effectSprite2.texture;
        break;

      default:
        this.spine!.setAnimation('ltf_effect3', false);
        const effectSprite3 = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.ltfEffect3));
        this.sprite.texture = effectSprite3.texture;
        break;
    }
  }

  private resultAnimation() {
    this.spine!.setAnimation('ltf_effect_result', false);
    const effectSpriteBig = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.ltfEffectResult));
    this.sprite.texture = effectSpriteBig.texture;
  }

  public remove() {
    this.removeChild(this.sprite);
    this.removeChild(this.text);
    if (this.spine!) {
      const delay = Tween.createDelayAnimation(0);
      delay.addOnComplete(() => {
        this.removeChild(this.spine!.getSpine());
        destroySpine(this.spine!);
        this.spine = undefined;
      });
      delay.start();
    }
  }

  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
}

export default ltfEffect;

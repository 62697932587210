import * as PIXI from 'pixi.js';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { infoFeatureBuy1 } from '../../config/gameRules';
import { FeatureState } from '../../global.d';
import { setSlotConfig } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import SlotMachine from '../../slotMachine';
import { getLaTomatinaCoinAmount, getTomatoSpinCoinAmount } from '../../utils';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

type infoFeatureTypes =
  | 'infoFeatureBuyDesc_1'
  | 'infoFeatureBuyDesc_2'
  | 'infoFeatureBuyDesc_3'
  | 'infoFeatureBuyDesc_4';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();
  const GreenTomato = Resources.getSource(ResourceTypes.symbolMystery);
  const RedTomato = Resources.getSource(ResourceTypes.symbolWildTomato);
  const [infoWayIndicatorIcon, setInfoWayIndicatorIcon] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoWayIndicatorIcon(extractor.base64(SlotMachine.getInstance().infoWayIndicatorIcon));
  }, []);

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }

  return (
    <section>
      <section>
        <h1 className={styles.title}>{t('infoFeatureFreeSpinTitle')}</h1>
        <h2 className={styles.title1}>{t('infoFeatureTitleTruckFeature')}</h2>
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTruckFeatureDesc1') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTruckFeatureDesc2') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTruckFeatureDesc3') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTruckFeatureDesc4') }} />
        <div className={styles.image}>
          <img draggable="false" src={GreenTomato} alt="Green tomato" />
          <div className={`${styles.p} ${styles.center}`}>{t(`infoFeatureTitleTruckFeatureGreenTomato`)}</div>
        </div>
        <h1 className={styles.title1}>{t('infoFeatureTitleTomatoFeature')}</h1>
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoFeatureDesc1') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoFeatureDesc2') }} />
        <div className={styles.image}>
          <img draggable="false" src={RedTomato} alt="Red tomato" />
          <div className={`${styles.p} ${styles.center}`}>{t(`infoFeatureTitleTomatoFeatureRedTomato`)}</div>
        </div>
        <h1 className={styles.title1}>{t('infoFeatureTitleArrowIndicator')}</h1>
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc1') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc2') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc3') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc4') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc5') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc6') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc7') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc8') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc9') }} />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc10') }}
        />
        <div className={styles.image}>
          <img draggable="false" src={infoWayIndicatorIcon} alt="WayIndicator" />
        </div>
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleArrowIndicatorDesc11') }}
        />
        <h1 className={styles.title1}>{t('infoFeatureTitleLaTomatinaFeature')}</h1>
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleLaTomatinaFeatureDesc1') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleLaTomatinaFeatureDesc2') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleLaTomatinaFeatureDesc3') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleLaTomatinaFeatureDesc4') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleLaTomatinaFeatureDesc5') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleLaTomatinaFeatureDesc6') }}
        />
        <h1 className={styles.title1}>{t('infoFeatureTitleTomatoAndSpinFeature')}</h1>
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc1') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc2') }}
        />
        <div className={styles.row}></div>
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc3') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc4') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc5') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc6') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc7') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc8') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc9') }}
        />
        <div className={styles.row}></div>
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc10') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc11') }}
        />
        <div
          className={`${styles.p}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleTomatoAndSpinFeatureDesc12') }}
        />
        <h1 className={styles.title1}>{t('infoFeatureTitleMaxWin')}</h1>
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleMaxWinDesc1') }} />
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureTitleMaxWinDesc2') }} />
      </section>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const multiplier = getLaTomatinaCoinAmount();
  const maxMultiplier = getTomatoSpinCoinAmount();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon));
  }, []);

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section>
      <h1 className={styles.title}>{t('infoFeatureBuyTitle')}</h1>
      <div className={styles.image}>
        <img draggable="false" src={infoBuyFeatureIcon} alt="buyFeatureBtn" />
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {infoFeatureBuy1.map(({ key, value }) => (
            <div className={styles.p} key={key}>
              {value === undefined && <div dangerouslySetInnerHTML={{ __html: t(key as infoFeatureTypes) }} />}
              {value === FeatureState.NORMAL && (
                <div dangerouslySetInnerHTML={{ __html: t(key as infoFeatureTypes, { multiplier }) }} />
              )}
              {value === FeatureState.MAX && (
                <div dangerouslySetInnerHTML={{ __html: t(key as infoFeatureTypes, { maxMultiplier }) }} />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div>
      <FreeSpinFeature />
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;

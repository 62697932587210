import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { isBaseGameMode, isBuyFeatureMode, isFreeSpinsMode } from '../../utils';
import ViewContainer from '../components/container';
import {
  CHARACTER_BOY_LANDSCAPE_POSITION_X,
  CHARACTER_BOY_LANDSCAPE_POSITION_Y,
  CHARACTER_BOY_PORTRAIT_POSITION_X,
  CHARACTER_BOY_PORTRAIT_POSITION_Y,
  CHARACTER_GIRL_LANDSCAPE_POSITION_X,
  CHARACTER_GIRL_LANDSCAPE_POSITION_Y,
  CHARACTER_GIRL_PORTRAIT_POSITION_X,
  CHARACTER_GIRL_PORTRAIT_POSITION_Y,
  eventManager,
} from '../config';

import Boy from './boy';
import Girl from './girl';

class Character extends ViewContainer {
  private boy = new Boy();
  private girl = new Girl();
  private invisibility = false;

  constructor() {
    super();

    this.addChild(this.boy);
    this.addChild(this.girl);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.setVisible.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.setVisible.bind(this));
  }

  public resize(width: number, height: number) {
    if (width > height) {
      this.visible = true;
      this.invisibility = false;
      this.boy.position.set(CHARACTER_BOY_LANDSCAPE_POSITION_X, CHARACTER_BOY_LANDSCAPE_POSITION_Y);
      this.girl.position.set(CHARACTER_GIRL_LANDSCAPE_POSITION_X, CHARACTER_GIRL_LANDSCAPE_POSITION_Y);
    } else if (height / width > 1.6) {
      this.visible = true;
      this.invisibility = false;
      this.boy.position.set(CHARACTER_BOY_PORTRAIT_POSITION_X, CHARACTER_BOY_PORTRAIT_POSITION_Y);
      this.girl.position.set(CHARACTER_GIRL_PORTRAIT_POSITION_X, CHARACTER_GIRL_PORTRAIT_POSITION_Y);
    } else {
      this.invisibility = true;
      this.visible = false;
    }

    if (isFreeSpinsMode(setGameMode())) {
      this.visible = false;
    }
  }

  private setVisible(settings: { mode: GameMode }): void {
    if (!this.invisibility && (isBaseGameMode(settings.mode) || isBuyFeatureMode(settings.mode))) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
}

export default Character;

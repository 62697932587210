export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  WL = 'WL',
  SC1 = 'SC1',
  v = 'v',
}

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'game ID',
    },
  },
  payTableData: [
    SlotId.SC1,
    SlotId.WL,
    SlotId.A,
    SlotId.B,
    SlotId.C,
    SlotId.D,
    SlotId.E,
    SlotId.F,
    SlotId.G,
    SlotId.H,
    SlotId.I,
    SlotId.J,
    SlotId.O,
    SlotId.P,
    SlotId.Q,
    SlotId.R,
  ],
} as const;

export enum Colors {
  WHITE_COLOR = 0xffffff,
  GAME_COLOR = 0xffcc48,
}

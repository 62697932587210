import _ from 'lodash';

import { SlotId } from '../../config';
import { GameMode, IBonus, ISettledBet, bonusIds } from '../../global.d';
import { setBonuses, setNextResult } from '../../gql/cache';
import { BASE_CREDIT } from '../../slotMachine/config';
import { Combos, Icon } from '../../slotMachine/d';

export const getCoinAmount = (nextResult: ISettledBet, isScatter: boolean, isExpand: boolean): number => {
  const payLine = nextResult.paylines;

  let rtn = 0;

  payLine.forEach((line) => {
    if (isExpand) {
      if (line.lineId == null) {
        // expand win
        if (
          line.winPositions.length > 0 &&
          setNextResult()?.bet.result.spinResult[line.winPositions[0]!]!.id != SlotId.WL
        ) {
          rtn = line.amount * 10;
        }
      }
    } else {
      if (line.lineId != null) {
        // normal win
        if (line.winPositions.length > 0) {
          rtn += line.amount;
        }
      } else {
        if (
          isScatter &&
          line.winPositions.length > 0 &&
          setNextResult()?.bet.result.spinResult[line.winPositions[0]!]!.id === SlotId.WL
        ) {
          // scatter win
          rtn += line.amount * 10;
        }
      }
    }
  });
  return rtn;
};

export const getLaTomatinaCoinAmount = (): number => {
  const bonuses = setBonuses();
  const bonus = _.chain(bonuses)
    .filter((bonus) => bonus.id === bonusIds[GameMode.BUY_FEATURE_LA_TOMATINA])
    .get(0, {})
    .value() as IBonus;

  return bonus.coinAmount / BASE_CREDIT;
};

export const getTomatoSpinCoinAmount = (): number => {
  const bonuses = setBonuses();
  const bonus = _.chain(bonuses)
    .filter((bonus) => bonus.id === bonusIds[GameMode.BUY_FEATURE_TOMATO_SPIN])
    .get(0, {})
    .value() as IBonus;

  return bonus.coinAmount / BASE_CREDIT;
};

export function getCombosDataFromIcon(icon: Icon): Combos {
  return icon.combos
    .flatMap((combo) => {
      return {
        pattern: combo.pattern,
        multiplier: combo.rewards[0]!.multiplier,
      };
    })
    .sort((a, b) => {
      const patternA = a.pattern;
      const patternB = b.pattern;
      if (patternA < patternB) {
        return 1;
      }
      if (patternA > patternB) {
        return -1;
      }
      return 0;
    });
}

export const easeOutBaseQuint = (x: number): number => {
  return 1 - Math.pow(1 - x, 7);
};

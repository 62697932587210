import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { destroySpine, isBuyFeatureMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { BG_LANDSCAPE_SPINE_Y, BG_PORTRAIT_SPINE_Y } from './config';

class Background extends ViewContainer {
  private baseBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotBg));
  private baseBgF = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotBgF));
  private baseBgF2 = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotBgF2));

  private bgSprite = new PIXI.Sprite();

  private animations: SpineAnimation[] = [];

  private localWidth = 0;

  private localHeight = 0;

  private localMode = GameMode.REGULAR;

  constructor() {
    super();
    this.bgSprite.texture = this.baseBg.texture;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.bgSprite.visible = true;
    this.bgSprite.y = 0;

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeModeBackground.bind(this));
  }

  private sceneChangeBaseGame(): void {
    this.bgSprite.texture = this.baseBg.texture;
  }

  private initFreeSpinsLaTomatina(): void {
    this.bgSprite.texture = this.baseBgF.texture;
  }

  private initFreeSpinsTomatoSpin(): void {
    this.bgSprite.texture = this.baseBgF2.texture;
  }

  private changeModeBackground(settings: { mode: GameMode }) {
    const { mode } = settings;
    if (mode === this.localMode) {
      return;
    }

    if (mode === GameMode.REGULAR || isBuyFeatureMode(mode)) {
      this.sceneChangeBaseGame();
    }

    if (mode === GameMode.FREE_SPIN_LA_TOMATINA) {
      this.sceneChangeLaTomatina();
    }

    if (mode === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO || mode === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
      this.sceneChangeTomatoSpin();
    }

    this.localMode = mode;
  }

  private removeAnimation() {
    this.animations.forEach((animation) => {
      this.removeChild(animation.getSpine());
      destroySpine(animation);
    });
    this.animations = [];
  }

  private sceneChangeLaTomatina() {
    this.removeAnimation();
    this.initFreeSpinsLaTomatina();
    this.bgChangeResize();
  }

  private sceneChangeTomatoSpin() {
    this.removeAnimation();
    this.initFreeSpinsTomatoSpin();
    this.bgChangeResize();
  }

  private bgChangeResize() {
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = this.localWidth / this.localHeight;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(this.localHeight / this.bgSprite.height);
      this.animations.forEach((animation) => {
        animation.spine.y = BG_PORTRAIT_SPINE_Y;
      });
    } else {
      this.scale.set(this.localWidth / this.bgSprite.width);
      this.animations.forEach((animation) => {
        animation.spine.y = BG_LANDSCAPE_SPINE_Y;
      });
    }
  }

  private resize(width: number, height: number): void {
    this.localWidth = width;
    this.localHeight = height;
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }

    if (setGameMode() === GameMode.REGULAR || isBuyFeatureMode(setGameMode())) {
      this.bgSprite.y = 0;
    } else {
      if (bgAspectRatio > aspectRatio) {
        this.animations.forEach((animation) => {
          animation.spine.y = BG_PORTRAIT_SPINE_Y;
        });
      } else {
        this.animations.forEach((animation) => {
          animation.spine.y = BG_LANDSCAPE_SPINE_Y;
        });
      }
    }
  }
}

export default Background;

import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setLatomatinaFeature } from '../../gql/cache';
import i18n from '../../i18next';
import { easeOutBaseQuint, updateTextScale } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager, laTomatinaStartTextStyle } from '../config';

import ltfCharacter from './ltfChara';
import TomatinaContainer from './tomatinaContainers';

class LaTomatina extends ViewContainer {
  private chara: ltfCharacter | undefined;
  private tomatinaContainer: TomatinaContainer | undefined;
  private isInit = false;
  private isStart = false;
  private laTomatinaText: PIXI.Text = new PIXI.Text(i18n.t('laTomatinaStartText'), laTomatinaStartTextStyle);
  constructor() {
    super();

    this.setVisible(false);

    eventManager.addListener(EventTypes.LATOMATINA, this.init.bind(this));
    eventManager.addListener(EventTypes.LATOMATINA_START, this.start.bind(this));
    eventManager.addListener(EventTypes.LATOMATINA_END, this.remove.bind(this));
  }

  private init() {
    this.isInit = true;
    this.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    this.pivot.set(0.5);

    this.chara = new ltfCharacter();
    this.chara.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(-SLOTS_CONTAINER_WIDTH / 2, -SLOTS_CONTAINER_HEIGHT / 2, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.chara.addChild(this.chara.mask);
    this.addChild(this.chara);
    this.tomatinaContainer = new TomatinaContainer();
    this.tomatinaContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.tomatinaContainer.addChild(this.tomatinaContainer.mask);
    this.addChild(this.tomatinaContainer);
    this.addChild(this.laTomatinaText);

    updateTextScale(this.laTomatinaText, SLOTS_CONTAINER_WIDTH - 200, isMobile ? 250 : 250);

    this.laTomatinaText.anchor.set(0.5);
    this.laTomatinaText.visible = true;

    this.setVisible(true);

    if (this.isStart === true) {
      this.laTomatinaText.visible = false;
      const delay = Tween.createDelayAnimation(1000);
      delay.addOnComplete(() => {
        this.start();
      });
      delay.start();
    }

    const animationChain = new AnimationChain();
    const textInAnimationGroup = new AnimationGroup({});
    const textWaitAnimationGroup = new AnimationGroup({});
    const textOutAnimationGroup = new AnimationGroup({});
    const textIn = new Tween({
      object: this.laTomatinaText,
      target: 1,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      duration: 500,
      easing: easeOutBaseQuint,
    });
    const textWait = new Tween({
      object: this.laTomatinaText,
      target: 1,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      duration: 500,
    });
    const textOut = new Tween({
      object: this.laTomatinaText,
      target: 0,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      duration: 500,
      easing: easeOutBaseQuint,
    });
    textInAnimationGroup.addAnimation(textIn);
    textWaitAnimationGroup.addAnimation(textWait);
    textOutAnimationGroup.addAnimation(textOut);
    animationChain.appendAnimation(textInAnimationGroup);
    animationChain.appendAnimation(textWaitAnimationGroup);
    animationChain.appendAnimation(textOutAnimationGroup);

    animationChain.start();
  }

  private start() {
    if (this.isInit === true) {
      this.laTomatinaText.visible = false;
      eventManager.emit(EventTypes.TOMATINA);
      eventManager.emit(EventTypes.RESET_ALL_MULTIPLIER);
    } else {
      this.isStart = true;
    }
  }

  private remove() {
    setLatomatinaFeature({
      multipliers: [],
      sumMultiplier: 0,
    });
    this.isInit = false;
    this.isStart = false;
    this.chara!.remove();
    this.tomatinaContainer!.remove();
    this.removeChildren();
  }

  private setVisible(visible: boolean) {
    this.visible = visible;
  }
}

export default LaTomatina;

import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { textExpansion } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { wayMultiplierTextStyle } from './textStyle';

class Way extends ViewContainer {
  private spine = new SpineAnimation({}, PIXI.Loader.shared.resources['way']!.spineData);
  private arrow: string;
  private text = new PIXI.Text();
  private multiplier = 0;
  private initMultiplier: number;

  public getMultiplier(): number {
    return this.multiplier;
  }

  constructor(arrow: string, x: number, y: number, initMultiplier: number) {
    super();

    this.position.set(x, y);
    this.arrow = arrow + '_';
    this.initMultiplier = initMultiplier;

    this.addChild(this.spine.getSpine());

    this.resetMultiplier();

    this.spine.start();
  }

  public changeMultiplier(multiplier: number, isExpand: boolean) {
    const isDiff = this.multiplier !== multiplier && isExpand;
    this.multiplier = 0;
    this.addMultiplier(multiplier, !isDiff, false);
  }

  public resetMultiplier() {
    this.multiplier = 0;
    this.addMultiplier(this.initMultiplier, true, false);
  }

  public addMultiplier(multiplier: number, isIdle: boolean, isPayline: boolean) {
    this.changeText(multiplier, !isIdle);

    if (this.multiplier > 0) {
      if (isIdle) {
        this.setIdle();
      } else {
        if (!isPayline) AudioApi.play({ type: ISongs.Indicator, stopPrev: true });
        if (this.multiplier === 1) {
          this.spine.setAnimation('way_indicator_' + this.arrow + 'on', false);
        } else {
          this.spine.setAnimation('way_indicator_' + this.arrow + 'onmulti', false);
        }

        this.spine.addOnComplete(() => {
          eventManager.emit(EventTypes.SET_IDLE_MULTIPLIER);
          this.spine.removeOnComplete(() => {});
        });
      }
    } else {
      this.spine.setAnimation('way_indicator_' + this.arrow + 'off', true);
    }
  }

  public setIdle() {
    if (this.multiplier === 1) {
      this.spine.setAnimation('way_indicator_' + this.arrow + 'onidle', true);
    } else if (this.multiplier > 1) {
      this.spine.setAnimation('way_indicator_' + this.arrow + 'onmultiidle', true);
    }
  }

  private changeText(multiplier: number, expansion: boolean) {
    if (this.text) this.removeChild(this.text);

    this.multiplier += multiplier;
    if (this.multiplier > 1) {
      this.text = new PIXI.Text('x' + this.multiplier, wayMultiplierTextStyle);
      this.text.anchor.set(0.5);
      this.text.position.set(0, 0);
      this.addChild(this.text);
      if (expansion) {
        textExpansion(this.text);
      }
    } else {
      if (this.text) {
        this.removeChild(this.text);
      }
    }
  }

  public payLineEffect() {
    if (this.multiplier > 0) {
      this.addMultiplier(0, false, true);
    }
  }
}

export default Way;

import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import ViewContainer from '../../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../../config';

class ReelsFrame extends ViewContainer {
  private frame: PIXI.Sprite;

  constructor() {
    super();
    this.frame = this.initFrame();

    this.addChild(this.frame);
  }

  private initFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelframe));
    frame.anchor.set(0.5);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    return frame;
  }
}

export default ReelsFrame;

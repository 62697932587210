import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

class ltfGun extends ViewContainer {
  private spine: SpineAnimation | undefined;
  constructor() {
    super();

    // this.init();
    this.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    this.pivot.set(0.5);

    eventManager.addListener(EventTypes.LATOMATINA, this.init.bind(this));
    eventManager.addListener(EventTypes.LATOMATINA_END, this.remove.bind(this));
  }

  private init() {
    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources['ltf_gun']!.spineData);
    this.spine.addOnStart(() => {
      this.addChild(this.spine!.getSpine());
      this.spine!.setAnimation('idle', true);
    });

    this.spine.start();

    eventManager.once(EventTypes.TOMATINA, this.shot.bind(this));
    eventManager.once(EventTypes.TOMATINA_BIG, this.lastShot.bind(this));
  }

  public shot() {
    this.spine!.setAnimation('shot', true);
    this.spine!.addOnComplete(() => {
      eventManager.emit(EventTypes.TOMATINA_HIT);
    });
  }

  private lastShot() {
    this.spine!.removeOnComplete(() => {
      eventManager.emit(EventTypes.TOMATINA_HIT);
    });
    this.spine!.setAnimation('lastshot', false);
    this.spine!.addOnComplete(() => {
      this.spine!.setAnimation('idle', true);
      eventManager.emit(EventTypes.TOMATINA_LAST);
    });
  }

  public remove() {
    if (this.spine!) {
      const delay = Tween.createDelayAnimation(0);
      delay.addOnComplete(() => {
        this.removeChild(this.spine!.getSpine());
        destroySpine(this.spine!);
        this.spine = undefined;
      });
      delay.start();
    }
  }

  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
}

export default ltfGun;

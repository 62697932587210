import type { SlotId } from './config';
import type { ReelSetType } from './gql/query';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState } from './slotMachine/config';
import type {
  /*IWinLine,*/
  Icon,
  LineSet,
} from './slotMachine/d';

declare global {
  interface Window {
    __ENV__: {
      REACT_APP_TRANSLATE_URL: string;
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_NAME: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface IBalance {
  amount: number;
  currency: string;
}

export interface IFeatures {
  gameRoundStore: {
    arrowMultipliers: {
      arrows: {
        O: IArrows;
        P: IArrows;
        Q: IArrows;
        R: IArrows;
        WL: IArrows;
      };
    };
    laTomatinaFeatureStore: ILaTomatinaFeatureStore;
    mysteryTruckFeature: {
      mappedIcons: IMappedIcons[];
    };
    outcomeGrid: SlotId[];
    tomatoFeatureStore: {
      mappedIcons: IMappedIcons[];
    };
    tomatoSpinFeatureMultipliersStore: {
      currentRound: number;
      roundsLeft: number;
    };
    tomatoSpinFeatureStore: {
      mode: string;
      round: number;
      sumPaylines: number;
    };
  };
}

export interface IMappedIcons {
  baseId: SlotId;
  resultId: SlotId;
}

export interface IArrows {
  count: number;
  matrixIndexes: number[];
}

export interface ILaTomatinaFeatureStore {
  multipliers: number[];
  sumMultiplier: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: IFeatures;
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
  rewards: BetReward[];
}

export type BetRewardType = 'BetBonusReward' | 'BetCoinReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};
export type BetReward = BetBonusReward | BetCoinReward;

export type BonusType = 'FREE_SPIN' | 'SPECIAL_ROUND' | 'PRE_LOADED' | 'GAME_MODE' | '';
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  totalRounds: number;
  betId: string;
  rounds: number;
  reelSetId: string;
  bonus: Bonus;
  totalWinAmount;
  coinValue: number;
  coinAmount: number;
  data: {
    count: number;
    creditMultiplier: number;
    debitMultiplier: number;
    initialRoundId: string;
    maxRound: number;
    rounds?: number;
  };
};
export type Bonus = {
  id: string;
  slotId: string;
  reelSetId: string;
  type: BonusType;
  title?: string;
  description?: string;
  lineSetId: string | null;
  coinAmount: number;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};

export type SlotIdAndPosition = {
  slot: SlotId;
  position: number;
};

export enum FeatureState {
  NORMAL,
  MAX,
}

export enum GameMode {
  REGULAR,
  FREE_SPIN_LA_TOMATINA,
  FREE_SPIN_TOMATO_SPIN_TOMATO,
  FREE_SPIN_TOMATO_SPIN_SPIN,
  BUY_FEATURE_LA_TOMATINA,
  BUY_FEATURE_TOMATO_SPIN,
}

export enum ReelId {
  REGULAR = 'a3dbd47d-d57f-44fd-a2f8-c20b4a63a4c6',
  REGULAR2 = 'afe803b7-1f40-46f4-8716-b667e9b1ef50',
  REGULAR3 = '674cb549-eaae-4720-843e-a641bbc0c961',
  REGULAR4 = 'ef4c7be2-f3e7-4f12-8bf1-c412a7d2ce65',
  REGULAR5 = '44a868f4-39d3-4b5a-9b73-8b46e3ef339d',
  REGULAR6 = 'a2b44e82-b538-4d18-89a4-dda02d035a7e',
  FREE_SPIN_LA_TOMATINA = '7a5565b4-ff66-4049-bb40-f9e3c07e85d0',
  FREE_SPIN_TOMATO_SPIN_TOMATO = 'fcf31324-4835-4340-b41a-84609c9012ed',
  FREE_SPIN_TOMATO_SPIN_SPIN = 'a3dbd47d-d57f-44fd-a2f8-c20b4a63a4c6',
  BUY_FEATURE_LA_TOMATINA = '1839621e-c089-446f-aeef-36c6b4e684c2',
  BUY_FEATURE_TOMATO_SPIN = 'a6082cbf-b675-4a0e-99e6-52c509b00173',
}

export enum Arrow {
  RIGHT = 0,
  LEFT = 1,
  UP = 2,
  DOWN = 3,
  NON = 10,
}

export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: ReelId.REGULAR,
  [GameMode.FREE_SPIN_LA_TOMATINA]: ReelId.FREE_SPIN_LA_TOMATINA,
  [GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO]: ReelId.FREE_SPIN_TOMATO_SPIN_TOMATO,
  [GameMode.FREE_SPIN_TOMATO_SPIN_SPIN]: ReelId.FREE_SPIN_TOMATO_SPIN_SPIN,
  [GameMode.BUY_FEATURE_LA_TOMATINA]: ReelId.BUY_FEATURE_LA_TOMATINA,
  [GameMode.BUY_FEATURE_TOMATO_SPIN]: ReelId.BUY_FEATURE_TOMATO_SPIN,
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.FREE_SPIN_LA_TOMATINA]: '906962e0-36d8-4074-b2c5-afab25ea13f8',
  [GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO]: 'ee701f07-559c-4213-890f-f26f4768cab8',
  [GameMode.FREE_SPIN_TOMATO_SPIN_SPIN]: 'bdbbb47f-6d17-414b-910f-d435d816ee29',
  [GameMode.BUY_FEATURE_LA_TOMATINA]: '8e27dcc1-cd77-4cf6-ae6f-b546e8d66986',
  [GameMode.BUY_FEATURE_TOMATO_SPIN]: '779cb66d-4978-4620-be76-faf470c8537a',
};

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '95459b3f-a0e0-493b-8b2d-94ee0a6db8cb',
  [GameMode.FREE_SPIN_LA_TOMATINA]: '95459b3f-a0e0-493b-8b2d-94ee0a6db8cb',
  [GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO]: '95459b3f-a0e0-493b-8b2d-94ee0a6db8cb',
  [GameMode.FREE_SPIN_TOMATO_SPIN_SPIN]: '95459b3f-a0e0-493b-8b2d-94ee0a6db8cb',
  [GameMode.BUY_FEATURE_LA_TOMATINA]: '95459b3f-a0e0-493b-8b2d-94ee0a6db8cb',
  [GameMode.BUY_FEATURE_TOMATO_SPIN]: '95459b3f-a0e0-493b-8b2d-94ee0a6db8cb',
};

export enum FeatureState {
  LA_TOMATINA,
  TOMATO_SPIN,
}
export const buyFeatureBonusesId = {
  [FeatureState.LA_TOMATINA]: '8e27dcc1-cd77-4cf6-ae6f-b546e8d66986',
  [FeatureState.TOMATO_SPIN]: '779cb66d-4978-4620-be76-faf470c8537a',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  reelSetId: string;
}
export interface GetUserBonusesInput {
  id: string;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type MessageBannerProps = {
  title: string;
  title2?: string | undefined;
  subTitle: string | undefined;
  btnText: string;
  winTitle: string;
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
};

export enum TotalWinBannerMode {
  NON,
  DISABLE,
  ENABLE,
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  BONUS = 'GAME_MODE',
  LA_TOMATINA = 'LA_TOMATINA',
  TOMATO_SPIN = 'TOMATO_SPIN',
}

export enum BgmSoundTypes {
  BASE = 'regular',
  LA_TOMATINA = 'LA_TOMATINA',
  TOMATO_SPIN_TOMATO = 'TOMATO_SPIN_TOMATO',
  TOMATO_SPIN_SPIN = 'TOMATO_SPIN_SPIN',
}

export enum ReelAnticipation {
  NON = 'NON',
  BONUS = 'BONUS',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_UI_BUTTON = 'resizeUiButton',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  SKIP_SPIN_ANIMATION = 'skipSpinAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREE_SPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_TOTAL_WIN_MESSAGE_BANNER = 'isTotalWinMessageBanner',
  SET_IS_COUNT_UP = 'setIsCountUp',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_STATE = 'setState',
  PHOENIX_START = 'PhoenixStart',
  UI_VISIBLE = 'UiVisible',
  SPIN_END = 'SpinEnd',
  WINDOW_NOT_ACTIVE = 'WindowNotActive',
  FREE_SPIN = 'FreeSpin',
  PLACE_BET_COMPLETED = 'placeBetCompleted',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  START_GET_AMOUNT_WIN = 'StartGetAmountWin',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  JINGLE_START = 'jingleStart',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  OPEN_POPUP = 'openPopup',
  CLOSE_POPUP = 'closePopup',
  SET_IS_FADEOUT = 'setIsFadeOut',
  SET_BROKEN_BUY_FEATURE = 'setBrokenFeature',
  TOMATO = 'tomato',
  TOMATO_DELETE = 'tomatoDelete',
  LATOMATINA = 'laTomatina',
  LATOMATINA_START = 'laTomatinaStart',
  LATOMATINA_END = 'laTomatinaEnd',
  TOMATO_SPIN = 'tomatoSpin',
  TOMATINA = 'tomatina',
  TOMATINA_BIG = 'tomatinaBig',
  TOMATINA_HIT = 'tomatinaHit',
  TOMATINA_LAST = 'tomatinaLast',
  ADD_WILD = 'addWild',
  RESET_WILD = 'resetWild',
  SET_WILD = 'setWild',
  ADD_MULTIPLIER = 'addMultiplier',
  RESET_MULTIPLIER = 'resetMultiplier',
  RESET_ALL_MULTIPLIER = 'resetAllMultiplier',
  SET_ALL_MULTIPLIER = 'setAllMultiplier',
  SET_IDLE_MULTIPLIER = 'setIdleMultiplier',
  PLAY_ANNOUNCE = 'playAnnounce',
  PLAY_ANNOUNCE_LOOP = 'playAnnounceLoop',
  MAKE_DUMMY_REEL = 'makeDummyReel',
  PAYLINE_EFFECT = 'payLineEffect',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_IS_TOMATINA = 'setIsTomatina',
  SET_COUNTDOWN = 'setCountdown',
  OPEN_MESSAGE_BANNER = 'openMessageBanner',
  CLOSE_MESSAGE_BANNER = 'closeMessageBanner',
  BACK_DROP_CLICK = 'backDropClick',
  SET_IS_PHOENIX = 'setIsPhoenix',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: (reel?: number) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: (isExpand: boolean, value?: number) => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (
    props: MessageBannerProps & {
      totalWin: string;
      totalSpins: number;
    },
  ) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HANDLE_CHANGE_BET_AMOUNT]: (betAmount: number) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_UI_BUTTON]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (
    nextResult: ISettledBet,
    isTurboSpin: boolean,
    isScatter: boolean,
    isExpand: boolean,
  ) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterNo: number[],
    anticipationStartReelId: number,
    anticipationEndReelId: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.SKIP_SPIN_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREE_SPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_TOTAL_WIN_MESSAGE_BANNER]: (isOpenedMessageBanner: setIsTotalWinMessageBanner) => void;
  [EventTypes.SET_IS_COUNT_UP]: (isCountUp: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.UI_VISIBLE]: () => void;
  [EventTypes.SPIN_END]: () => void;
  [EventTypes.WINDOW_NOT_ACTIVE]: () => void;
  [EventTypes.FREE_SPIN]: (isTurboSpin: boolean | undefined) => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: (mode: GameMode) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (total: number, current: number, immediately?: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.START_GET_AMOUNT_WIN]: () => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.REMOVE_FREE_SPINS_TITLE]: () => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageBannerProps) => void;
  [EventTypes.JINGLE_START]: () => void;

  [EventTypes.OPEN_POPUP]: () => void;
  [EventTypes.CLOSE_POPUP]: () => void;
  [EventTypes.SET_IS_FADEOUT]: (boolean) => void;
  [EventTypes.SET_BROKEN_BUY_FEATURE]: (boolean) => void;

  [EventTypes.TOMATO]: (features: IFeatures) => void;
  [EventTypes.TOMATO_DELETE]: () => void;

  [EventTypes.LATOMATINA]: () => void;
  [EventTypes.LATOMATINA_START]: () => void;
  [EventTypes.LATOMATINA_END]: () => void;

  [EventTypes.TOMATO_SPIN]: () => void;

  [EventTypes.TOMATINA]: () => void;
  [EventTypes.TOMATINA_BIG]: () => void;
  [EventTypes.TOMATINA_HIT]: () => void;
  [EventTypes.TOMATINA_LAST]: () => void;

  [EventTypes.ADD_WILD]: (wild: number) => void;
  [EventTypes.RESET_WILD]: () => void;
  [EventTypes.SET_WILD]: (isExpand: boolean) => void;

  [EventTypes.ADD_MULTIPLIER]: (slot: SlotId) => void;
  [EventTypes.RESET_MULTIPLIER]: (arrow: Arrow) => void;
  [EventTypes.RESET_ALL_MULTIPLIER]: () => void;
  [EventTypes.SET_ALL_MULTIPLIER]: (isExpand: boolean) => void;
  [EventTypes.SET_IDLE_MULTIPLIER]: () => void;

  [EventTypes.PLAY_ANNOUNCE]: (slot: SlotId, position: { x: number; y: number }) => void;
  [EventTypes.PLAY_ANNOUNCE_LOOP]: (slot: SlotId, position: { x: number; y: number }) => void;

  [EventTypes.MAKE_DUMMY_REEL]: (
    reelSetId: string,
    reelPositions: number[],
    isBlank: boolean,
    features?: IFeatures,
  ) => void;
  [EventTypes.PAYLINE_EFFECT]: (arrow: Arrow) => void;
  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.SET_IS_TOMATINA]: (isTomatina: boolean) => void;

  [EventTypes.SET_COUNTDOWN]: (count: number, isExpand: boolean) => void;
  [EventTypes.OPEN_MESSAGE_BANNER]: () => void;
  [EventTypes.CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.BACK_DROP_CLICK]: () => void;
  [EventTypes.SET_IS_PHOENIX]: (isPhoenix: boolean) => void;
}

export enum PopupTypes {
  BUY_FEATURE,
  BUY_FEATURE_CONFIRMATION,
  FREE_SPINS,
  FREE_SPINS_END,
  RAGE_MODE,
  RAGE_MODE_INFO,
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
  TURBO_SPIN = 'TURBO_SPIN',
}

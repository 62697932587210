import { IFeatures, ReelSet } from '../global';
import { IPlayerSettings, Icon, LineSet } from '../slotMachine/d';

export declare interface IConfig {
  isAuthorized: boolean;
  isMobile: boolean;
  isTurboSpin: boolean;
  isSoundOn: boolean;
  isSpinInProgress: boolean;
  isEnabledSpaceSpin: boolean;
  isSlotBusy: boolean;
  isOpenAutoplayModal: boolean;
  isOpenBetSettingsModal: boolean;
  isSlotStopped: boolean;
  isOpenMenuModal: {
    isOpen: boolean;
    type: 'menu' | 'info';
  };
  isOpenHistoryPopup: boolean;
  isOpenAutoplayPopup: boolean;
  isOpenBetSettingsPopup: boolean;
  isOpenInfoPopup: boolean;
  [key: string]: boolean | unknown;
}

export declare interface IBetSettings {
  minBet: number;
  maxBet: number;
  bets: number[];
  quick: number[];
  coinValue: number;
  minBetCount: number;
  maxBetCount: number;
}
export type DataGQl = {
  storeFieldName: string;
};

export declare interface ISlotConfig {
  id: string;
  lineSet: LineSet;
  lines: number[];
  settings: {
    betLines: {
      min: number;
      max: number;
    };
    startPosition: number[];
    playerSettings: IPlayerSettings;
  };
  clientSettings: {
    coinAmounts: {
      default: number[];
      quick: number[];
    };
    coinValues: {
      code: string;
      variants: number[];
    }[];
    features: Features[];
    autoplay: {
      options: number[];
      conditions: {
        stopOnAnyWin: {
          enabled: boolean;
        };
        stopIfFeatureIsWon: {
          enabled: boolean;
        };
        stopIfSingleWinExceeds: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceDecreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceIncreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
      };
    };
  };
  sessionId: string;
  reels: ReelSet[];
  winLines: number[][];
  icons: Icon[];
  isBuyFeatureEnabled: boolean;
}

export declare interface Features {
  id: string;
  enabled: boolean;
}

export declare interface IUserBonus {
  bonus: {
    coinAmount: number;
  };
  betId: string | undefined;
  coinAmount: number;
  coinValue: number;
}

export declare interface IBet {
  id: string;
  coinAmount: number;
  coinValue: number;
  data: {
    features: IFeatures;
  };
  result: {
    reelPositions: number[];
    winCoinAmount: number;
  };
  reelSetId: string;
  createdAt: string;
  updatedAt: string;
  userBonus: IUserBonus | undefined;
}

export declare interface IBetResult {
  amount: number;
  id: string;
  lines: number[];
  paylines: IWinLine[];
  reelPositions: number[];
  slotId: string;
  spinResult: Icon[];
}

export declare interface IWinLine {
  lineId: number;
  amount: number;
  winPositions: number[];
}

export declare enum IconType {
  EMPTY = 'EMPTY',
  REGULAR = 'REGULAR',
  SCATTER = 'SCATTER',
  WILD = 'WILD',
}

export declare enum PayoffType {
  LTR = 'LTR',
  RTL = 'RTL',
  ANY = 'ANY',
  ADJACENT = 'ADJACENT',
}
export declare enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}
export declare interface Reward {
  bonusId: string | null;
  count: number | null;
  multiplier: number | null;
  type: RewardType;
}
export declare interface IconCombo {
  pattern: string;
  multiplier: number;
}
export type Combos = IconCombo[] | undefined;
export declare interface Combo {
  rewards: Reward[];
  pattern: string;
  payoffType: string;
  type: string;
}

export declare interface ISlotParameter {
  id: string;
  isBuyFeatureEnabled: boolean;
  sessionId: string;
}

export declare interface ISlotHistory {
  gameId: string;
  date: string | number;
  bet: number | string;
  win: number | string;
  cursor: string;
  [key: string]: string | number;
}

export declare interface ISlotHistoryData {
  edges: {
    cursor: string;
    node: IBet;
  }[];
  pageInfo: {
    count: number;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

export declare interface IStressful {
  show: boolean;
  type: 'network' | 'balance' | 'session' | 'none' | 'slotMachine';
  message: string;
}

export enum ReelSetType {
  DEFAULT = 'DEFAULT',
  BONUS = 'BONUS',
}

import type React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const AutoPlay: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.autoplay}>
      <h1 className={styles.title}>{t('infoAutoPlayTitle')}</h1>
      <div className={styles.row}>
        <div className={styles.col}>
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: t('infoAutoPlay1'),
            }}
          />
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: t('infoAutoPlay1_1'),
            }}
          />
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: t('infoAutoPlay2'),
            }}
          />
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: t('infoAutoPlay3'),
            }}
          />
        </div>
        <div className={styles.col}>
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: t('infoAutoPlay4'),
            }}
          />
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: t('infoAutoPlay5'),
            }}
          />
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: t('infoAutoPlay6'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoPlay;

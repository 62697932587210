import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { Arrow, EventTypes } from '../../global.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

class PayLineEffect extends ViewContainer {
  constructor() {
    super();

    eventManager.addListener(EventTypes.PAYLINE_EFFECT, this.departure.bind(this));
  }

  private departure(arrow: Arrow) {
    const spine = new SpineAnimation({}, PIXI.Loader.shared.resources['payline']!.spineData);
    this.pivot.set(0.5);
    spine.addOnStart(() => {
      this.addChild(spine.getSpine());
      arrow += 1;
      spine.setAnimation('payline_effect' + arrow, false);
      AudioApi.stop({ type: ISongs.Effect_payout });
      AudioApi.play({ type: ISongs.Effect_payout });
    });
    this.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    spine.start();

    spine.addOnComplete(() => {
      const delay = Tween.createDelayAnimation(0);
      delay.addOnComplete(() => {
        this.removeChild(spine.getSpine());
        destroySpine(spine);
      });
      delay.start();
    });

    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, () => {
      spine.onComplete();
      spine.removeOnComplete(() => {});
    });
  }
}

export default PayLineEffect;

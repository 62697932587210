import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, IFeatures } from '../../global.d';
import { setDelayFeatureNum, setDelayWinAnimation, setGameMode, setIsForceStop, setIsTurboSpin } from '../../gql/cache';
import { isFreeSpinsMode } from '../../utils';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { REELS_AMOUNT, eventManager } from '../config';

import Truck from './truck';

class TrackContainer extends ViewContainer {
  private truck: Truck[] = [];
  private sound = false;

  constructor() {
    super();

    this.position.set(0, 0);

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.truck[i] = new Truck();
      this.addChild(this.truck[i]!);
    }

    eventManager.addListener(EventTypes.TOMATO, this.truckCreate.bind(this));
    eventManager.addListener(EventTypes.TOMATO_DELETE, this.truckDelete.bind(this));
  }

  private truckCreate(features: IFeatures): void {
    if (features.gameRoundStore.mysteryTruckFeature === undefined) return;
    this.sound = Object.entries(features.gameRoundStore.mysteryTruckFeature.mappedIcons).length > 0 ? true : false;
    const truckCol: number[] = [];
    if (isFreeSpinsMode(setGameMode())) return;

    // TRACK
    Object.entries(features.gameRoundStore.mysteryTruckFeature.mappedIcons).forEach(([index]) => {
      const column = Number(index);
      if (column < 6) {
        truckCol.push(column);
      }
    });

    if (truckCol.length > 0) setDelayWinAnimation(1000);

    if (truckCol.length > 0 && setIsTurboSpin()) {
      setDelayFeatureNum(1);
    }

    for (let i = 0; i < REELS_AMOUNT; i++) {
      if (i < truckCol.length) {
        const delay = Tween.createDelayAnimation(setIsTurboSpin() ? 0 : i * 250);
        delay.addOnComplete(() => {
          this.truck[i]!.departure(truckCol[i]!);
        });
        delay.start();

        eventManager.addListener(EventTypes.SKIP_SPIN_ANIMATION, () => {
          delay.removeOnComplete(() => {});
        });
      }
    }
  }

  private truckDelete(): void {
    if (setIsForceStop()) {
      if (this.sound) {
        AudioApi.play({ type: ISongs.Truck2 });
      }
      return;
    }
    this.truck.forEach((i) => {
      i.end();
    });
  }
}

export default TrackContainer;

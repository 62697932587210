import { ITextStyle, InteractionEvent, Texture } from 'pixi.js';

import { filterBuyMouseActive, filterBuyMouseDown, filterBuyMouseOver } from '../../utils';

import { SpriteButton, SpriteButtonProps, SpriteButtonState } from './SpriteButton';

export interface FeatureButtonProps extends SpriteButtonProps {
  [SpriteButtonState.ACTIVE]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
  };
  isActive?: boolean;
}
export class FeatureButton extends SpriteButton {
  private isActive = false;

  constructor(props: FeatureButtonProps) {
    super(props);
    this.isActive = !!props.isActive;
    this.state = this.isActive ? SpriteButtonState.ACTIVE : SpriteButtonState.DEFAULT;

    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    }
    if (this.textStyles) {
      this.textStyles[SpriteButtonState.ACTIVE] = props[SpriteButtonState.ACTIVE]?.textStyle!;
    }
  }

  public turnOn(): void {
    this.isActive = true;
    this.changeState(SpriteButtonState.ACTIVE);
  }

  public turnOff(): void {
    this.isActive = false;
    this.changeState(SpriteButtonState.DEFAULT);
  }

  protected override onPointerOverCallback(): void {
    if (this.isPointerDown) return;
    if (!this.isActive) super.onPointerOverCallback();
  }

  protected override onPointerTapCallback(event: InteractionEvent): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.isPointerDown = false;
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
      if (this.onClick && event.data.isPrimary) {
        this.onClick();
      }
    }
  }

  protected override onPointerOutCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.isPointerDown) return;
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
      if (this.onPointerOut) {
        this.onPointerOut();
      }
    }
  }

  protected override onPointerUpOutSideCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.isPointerDown = false;
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
    }
  }

  public override enable(): void {
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
    }
    this.interactive = true;
  }

  protected override changeState(state: SpriteButtonState): void {
    this.state = state;

    switch (state) {
      case SpriteButtonState.DEFAULT:
        this.filters = null;
        break;
      case SpriteButtonState.HOVER:
        this.filters = [filterBuyMouseOver()];
        break;
      case SpriteButtonState.PRESSED:
        this.filters = [filterBuyMouseDown()];
        break;
      case SpriteButtonState.DISABLED:
        this.filters = null;
        break;
      case SpriteButtonState.ACTIVE:
        this.filters = [filterBuyMouseActive()];
        break;
      default:
        break;
    }
  }
}

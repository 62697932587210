import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class Backdrop extends ViewContainer {
  private backdrop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backdrop));

  constructor() {
    super();

    this.addChild(this.backdrop);
    this.backdrop.visible = false;
    this.backdrop.alpha = 0.9;
    this.backdrop.interactive = true;
    this.expandBackdropOverScreen();

    this.trackBuyFeaturePopupVisibility();
  }

  public trackBuyFeaturePopupVisibility(): void {
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, () => {
      this.backdrop.visible = true;
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG, () => {
      this.backdrop.visible = false;
    });
  }

  // Workaround to position backdrop over view in all landscapes
  public expandBackdropOverScreen(): void {
    this.backdrop.scale.set(4);
    this.x = -(this.backdrop.width / 2);
    this.y = -(this.backdrop.height / 2);
  }
}
export default Backdrop;

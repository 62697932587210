import { useMutation } from '@apollo/client';
import * as _ from 'lodash';
import React, { useEffect } from 'react';

import { EventTypes, FeatureState, GameMode, IBonus, bonusIds, buyFeatureBonusesId, reelSets } from '../../global.d';
import { setBonuses, setCoinAmount, setCoinValue, setCurrentBonus, setCurrentBonusId } from '../../gql/cache';
import { buyBonusGql } from '../../gql/mutation';
import { isStoppedGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';

const BuyFeature: React.FC = () => {
  const [buyBonus, { client }] = useMutation(buyBonusGql, {
    onCompleted(data: { buyBonus: { id: string; bonusId: string; balance: { amount: number; currency: string } } }) {
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, data.buyBonus.balance);
      setCurrentBonusId(data.buyBonus.id);
      setCurrentBonus({
        ...setCurrentBonus(),
        id: data.buyBonus.id,
        bonusId: data.buyBonus.id,
        coinAmount: setCoinAmount(),
        totalRounds: 0,
        isActive: true,
        currentRound: 0,
        rounds: data.buyBonus.bonusId === bonusIds[GameMode.BUY_FEATURE_LA_TOMATINA] ? 1 : 3,
      });

      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode:
          data.buyBonus.bonusId === buyFeatureBonusesId[FeatureState.TOMATO_SPIN]
            ? GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO
            : GameMode.FREE_SPIN_LA_TOMATINA,
        reelSetId:
          data.buyBonus.bonusId === buyFeatureBonusesId[FeatureState.TOMATO_SPIN]
            ? reelSets[GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO]!
            : reelSets[GameMode.FREE_SPIN_LA_TOMATINA]!,
        reelPositions: [0, 0, 0, 0, 0, 0],
        fadeOutDuration: 1000,
        fadeInDuration: 1000,
      });
    },
  });

  useEffect(() => {
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      const bonuses = setBonuses();
      const bonus = _.chain(bonuses)
        .filter((bonus) => bonus.id === bonusId)
        .get(0, {})
        .value() as IBonus;
      buyBonus({
        variables: {
          input: {
            id: bonus.id,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default BuyFeature;

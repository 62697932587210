import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';

import { wayMultiplierTextStyle } from './textStyle';

class WayIndicatorIcon extends PIXI.Container {
  private arrow: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = true;
    this.arrow = this.initWayIndicator();
    this.addChild(this.arrow);

    this.text = this.initWayIndicatorText();
    this.addChild(this.text);
  }

  private initWayIndicator() {
    const arrow = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.panelRight));
    arrow.x = 0;
    arrow.y = 0;
    arrow.interactive = true;
    return arrow;
  }

  private initWayIndicatorText(): PIXI.Text {
    const text = new PIXI.Text('x3', wayMultiplierTextStyle);

    text.anchor.set(0.5);
    text.position.set(this.width / 2, this.height / 2);
    updateTextScale(text, 120, 100);

    return text;
  }
}

export default WayIndicatorIcon;

import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, bonusIds } from '../../global.d';
import { setIsBuyFeaturePopupOpened, setIsBuyFeaturePurchased, setTomatoAndSpins } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import { SpriteButton } from '../components/SpriteButton';
import {
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_HEIGHT,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_X,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_WIDTH,
  eventManager,
} from '../config';

import { betValueStyleConfirm, buyFeatureConfirmStyle, totalCostTextStyle } from './textStyles';

class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private coinAmount: number;

  private titleFreeSpinsText: PIXI.Text;

  private titleMaxFreeSpinsText: PIXI.Text;

  private totalCostText: PIXI.Text;

  private totalCostValue: PIXI.Text;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  constructor() {
    super();
    this.visible = false;
    this.coinAmount = 1;
    this.popupBg = this.initPopupBg();
    this.titleFreeSpinsText = this.initFreeSpinsTitle();
    this.titleMaxFreeSpinsText = this.initMaxFreeSpinsTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string, coinAmount: number) => {
      this.handleOpen(totalCost, coinAmount);
    });
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      setIsBuyFeaturePopupOpened(false);
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.titleFreeSpinsText);
    this.addChild(this.titleMaxFreeSpinsText);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.anchor.set(0.5, 0.5);
    popupBg.position.set(FEATURE_POPUP_CONFIRM_WIDTH, FEATURE_POPUP_CONFIRM_HEIGHT);
    return popupBg;
  }

  private initFreeSpinsTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t('BuyFeature_ConfirmTitle_LaTomatina'), buyFeatureConfirmStyle);

    title.x = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, this.popupBg.width - 1500, isMobile ? 250 : 250);

    return title;
  }

  private initMaxFreeSpinsTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t('BuyFeature_ConfirmTitle_TomatoAndSpin'), buyFeatureConfirmStyle);

    title.x = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, this.popupBg.width - 1500, isMobile ? 250 : 250);

    return title;
  }

  private initTotalCostText(): PIXI.Text {
    const totalCostText = new PIXI.Text(i18n.t('buyFeatureTotalCost'), totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;
    totalCostText.x = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X;
    totalCostText.anchor.set(0.5, 0);
    updateTextScale(totalCostText, this.popupBg.width - 100, isMobile ? 250 : 250);
    return totalCostText;
  }

  private initTotalCostValue(): PIXI.Text {
    const totalCostValue = new PIXI.Text('0', betValueStyleConfirm);
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;
    totalCostValue.x = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X;
    totalCostValue.anchor.set(0.5, 0);

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn),
      onClick: () => {
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    });
    cancelBtn.y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5, 0);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn),
      onClick: this.handleClickOk.bind(this),
    });
    okBtn.y = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5, 0);

    return okBtn;
  }

  private handleClickOk(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    AudioApi.play({ type: ISongs.SFX_BuyFeature });
    let bonusId;
    if (!setTomatoAndSpins()) {
      bonusId = bonusIds[GameMode.BUY_FEATURE_LA_TOMATINA]!;
    } else {
      bonusId = bonusIds[GameMode.BUY_FEATURE_TOMATO_SPIN]!;
    }
    eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, bonusId);
    setIsBuyFeaturePurchased(true);
  }

  public handleOpen(totalCost: string, coinAmount: number): void {
    this.visible = true;
    if (setTomatoAndSpins()) {
      this.titleFreeSpinsText.visible = false;
      this.titleMaxFreeSpinsText.visible = true;
    } else {
      this.titleFreeSpinsText.visible = true;
      this.titleMaxFreeSpinsText.visible = false;
    }
    this.coinAmount = coinAmount;
    this.totalCostValue.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public handleClose(): void {
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP);
    this.cancelBtn.enable();
    this.okBtn.enable();
    this.visible = false;
  }
}

export default BuyFeaturePopupConfirm;

import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class ltfCharacter extends ViewContainer {
  private spine: SpineAnimation | undefined;
  private isHit = false;
  constructor() {
    super();

    this.init();

    eventManager.addListener(EventTypes.TOMATINA_HIT, this.hit.bind(this));

    eventManager.once(EventTypes.TOMATINA_BIG, () => {
      this.isHit = true;
      this.hit();
    });

    eventManager.once(EventTypes.TOMATINA_LAST, () => {
      this.isHit = false;
      this.hit();
    });
  }

  private init() {
    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources['ltf_chara']!.spineData);
    this.spine.addOnStart(() => {
      this.addChild(this.spine!.getSpine());
      this.spine!.setAnimation('idle', true);
    });

    this.spine.start();
  }

  private hit() {
    if (!this.isHit) {
      this.spine!.setAnimation('hit', true);
      this.isHit = true;
    }
  }

  public remove() {
    eventManager.removeAllListeners(EventTypes.TOMATINA_HIT);
    if (this.spine!) {
      const delay = Tween.createDelayAnimation(0);
      delay.addOnComplete(() => {
        this.removeChild(this.spine!.getSpine());
        destroySpine(this.spine!);
        this.spine = undefined;
      });
      delay.start();
    }
  }

  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
}

export default ltfCharacter;

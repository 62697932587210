import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { REEL_HEIGHT, REEL_WIDTH, eventManager } from '../config';

class Truck extends ViewContainer {
  private spine: SpineAnimation | undefined;
  constructor() {
    super();

    eventManager.addListener(EventTypes.SET_IS_SLOTS_STOPPED, this.remove.bind(this));
  }

  public departure(col: number) {
    this.setVisible(true);
    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources['truck']!.spineData);
    this.pivot.set(0.5);
    this.spine!.setAnimation('truck_feature_in', false);
    this.spine!.addAnimation('truck_feature_loop', true, 0);

    this.spine.addOnStart(() => {
      this.addChild(this.spine!.getSpine());
      AudioApi.stop({ type: ISongs.Truck1 });
      AudioApi.play({ type: ISongs.Truck1 });
    });
    this.spine.addOnSkip(() => {
      this.setVisible(false);
    });
    this.position.set(col * REEL_WIDTH + REEL_WIDTH / 2, 3 * REEL_HEIGHT);

    this.spine!.start();
  }

  public end() {
    if (this.spine!) {
      this.spine!.setAnimation('truck_feature_out', false);
      AudioApi.stop({ type: ISongs.Truck2 });
      AudioApi.play({ type: ISongs.Truck2 });
      this.spine!.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.Truck3 });
        AudioApi.play({ type: ISongs.Truck3 });
        this.remove();
      });
    }
  }

  public remove() {
    if (this.spine?.getSpine()) {
      const delay = Tween.createDelayAnimation(0);
      delay.addOnComplete(() => {
        this.removeChild(this.spine!.getSpine());
        destroySpine(this.spine!);
        this.spine = undefined;
      });
      delay.start();
    }
  }

  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
}

export default Truck;

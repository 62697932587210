import { ITextStyle, TEXT_GRADIENT, Text, TextMetrics, TextStyle, utils } from 'pixi.js';

export interface IGradientStrokeTextStyle extends Omit<ITextStyle, 'stroke'> {
  stroke: TextStyleStroke;
  strokeGradientType: TEXT_GRADIENT;
  strokeGradientStops: number[];
}

export class GradientStrokeText extends Text {
  private stroke: TextStyleStroke = '#000000';

  private strokeGradientType: TEXT_GRADIENT = TEXT_GRADIENT.LINEAR_VERTICAL;

  private strokeGradientStops: number[] = [];

  constructor(text: string, style: Partial<IGradientStrokeTextStyle>) {
    const { stroke, strokeGradientType, strokeGradientStops, ...textStyle } = style;

    super(text, textStyle);

    if (stroke) this.stroke = stroke;
    if (strokeGradientStops) this.strokeGradientStops = strokeGradientStops;
    if (strokeGradientType) this.strokeGradientType = strokeGradientType;
  }

  /**
   * Generates the stroke style. Can automatically generate a gradient based on the stroke style being an array
   * @param style - The style.
   * @param lines - The lines of text.
   * @param metrics
   * @returns The stroke style
   */
  private _generateStrokeStyle(
    style: TextStyle,
    lines: string[],
    metrics: TextMetrics,
  ): string | CanvasGradient | CanvasPattern {
    const strokeStyle: string | string[] | CanvasGradient | CanvasPattern = this.stroke;

    if (!Array.isArray(strokeStyle)) {
      return strokeStyle;
    } else if (strokeStyle.length === 1) {
      return strokeStyle[0]!;
    }

    // the gradient will be evenly spaced out according to how large the array is.
    // ['#FF0000', '#00FF00', '#0000FF'] would created stops at 0.25, 0.5 and 0.75
    let gradient: string[] | CanvasGradient;

    // a dropshadow will enlarge the canvas and result in the gradient being
    // generated with the incorrect dimensions
    const dropShadowCorrection = style.dropShadow ? style.dropShadowDistance : 0;

    // should also take padding into account, padding can offset the gradient
    const padding = style.padding || 0;

    const width = this.canvas.width / this._resolution - dropShadowCorrection - padding * 2;
    const height = this.canvas.height / this._resolution - dropShadowCorrection - padding * 2;

    // make a copy of the style settings, so we can manipulate them later
    const colorArray = strokeStyle.slice();
    const strokeGradientStops = this.strokeGradientStops.slice();

    // wanting to evenly distribute the fills. So an array of 4 colours should give fills of 0.25, 0.5 and 0.75
    if (!strokeGradientStops.length) {
      const lengthPlus1 = colorArray.length + 1;

      for (let i = 1; i < lengthPlus1; ++i) {
        strokeGradientStops.push(i / lengthPlus1);
      }
    }

    // stop the bleeding of the last gradient on the line above to the top gradient of the this line
    // by hard defining the first gradient colour at point 0, and last gradient colour at point 1
    colorArray.unshift(strokeStyle[0]!);
    strokeGradientStops.unshift(0);

    colorArray.push(strokeStyle[strokeStyle.length - 1]!);
    strokeGradientStops.push(1);

    if (this.strokeGradientType === TEXT_GRADIENT.LINEAR_VERTICAL) {
      // start the gradient at the top center of the canvas, and end at the bottom middle of the canvas
      gradient = this.context.createLinearGradient(width / 2, padding, width / 2, height + padding);

      // we need to repeat the gradient so that each individual line of text has the same vertical gradient effect
      // ['#FF0000', '#00FF00', '#0000FF'] over 2 lines would create stops at 0.125, 0.25, 0.375, 0.625, 0.75, 0.875

      // Actual height of the text itself, not counting spacing for lineHeight/leading/dropShadow etc
      const textHeight = metrics.fontProperties.fontSize + style.strokeThickness;

      for (let i = 0; i < lines.length; i++) {
        const lastLineBottom = metrics.lineHeight * (i - 1) + textHeight;
        const thisLineTop = metrics.lineHeight * i;
        let thisLineGradientStart = thisLineTop;

        // Handle case where last & this line overlap
        if (i > 0 && lastLineBottom > thisLineTop) {
          thisLineGradientStart = (thisLineTop + lastLineBottom) / 2;
        }

        const thisLineBottom = thisLineTop + textHeight;
        const nextLineTop = metrics.lineHeight * (i + 1);
        let thisLineGradientEnd = thisLineBottom;

        // Handle case where this & next line overlap
        if (i + 1 < lines.length && nextLineTop < thisLineBottom) {
          thisLineGradientEnd = (thisLineBottom + nextLineTop) / 2;
        }

        // textHeight, but as a 0-1 size in global gradient stop space
        const gradStopLineHeight = (thisLineGradientEnd - thisLineGradientStart) / height;

        for (let j = 0; j < colorArray.length; j++) {
          // 0-1 stop point for the current line, multiplied to global space afterwards
          let lineStop = 0;

          if (typeof strokeGradientStops[j] === 'number') {
            lineStop = strokeGradientStops[j]!;
          } else {
            lineStop = j / colorArray.length;
          }

          let globalStop = Math.min(1, Math.max(0, thisLineGradientStart / height + lineStop * gradStopLineHeight));

          // There's potential for floating point precision issues at the seams between gradient repeats.
          globalStop = Number(globalStop.toFixed(5));
          gradient.addColorStop(globalStop, colorArray[j]!);
        }
      }
    } else {
      // start the gradient at the center left of the canvas, and end at the center right of the canvas
      gradient = this.context.createLinearGradient(padding, height / 2, width + padding, height / 2);

      // can just evenly space out the gradients in this case, as multiple lines makes no difference
      // to an even left to right gradient
      const totalIterations = colorArray.length + 1;
      let currentIteration = 1;

      for (let i = 0; i < colorArray.length; i++) {
        let stop: number;

        if (typeof strokeGradientStops[i] === 'number') {
          stop = strokeGradientStops[i]!;
        } else {
          stop = currentIteration / totalIterations;
        }
        gradient.addColorStop(stop, colorArray[i]!);
        currentIteration++;
      }
    }

    return gradient;
  }

  /**
   * Renders text to its canvas, and updates its texture.
   *
   * By default this is used internally to ensure the texture is correct before rendering,
   * but it can be used called externally, for example from this class to 'pre-generate' the texture from a piece of text,
   * and then shared across multiple Sprites.
   * @param respectDirty - Whether to abort updating the text if the Text isn't dirty and the function is called.
   */
  public override updateText(respectDirty: boolean): void {
    const style = this._style;

    // check if style has changed..
    if (this.localStyleID !== style.styleID) {
      this.dirty = true;
      this.localStyleID = style.styleID;
    }

    if (!this.dirty && respectDirty) {
      return;
    }

    this._font = this._style.toFontString();

    const context = this.context;
    const measured = TextMetrics.measureText(this._text || ' ', this._style, this._style.wordWrap, this.canvas);
    const width = measured.width;
    const height = measured.height;
    const lines = measured.lines;
    const lineHeight = measured.lineHeight;
    const lineWidths = measured.lineWidths;
    const maxLineWidth = measured.maxLineWidth;
    const fontProperties = measured.fontProperties;

    this.canvas.width = Math.ceil(Math.ceil(Math.max(1, width) + style.padding * 2) * this._resolution);
    this.canvas.height = Math.ceil(Math.ceil(Math.max(1, height) + style.padding * 2) * this._resolution);

    context.scale(this._resolution, this._resolution);

    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    context.font = this._font;
    context.lineWidth = style.strokeThickness;
    context.textBaseline = style.textBaseline;
    context.lineJoin = style.lineJoin;
    context.miterLimit = style.miterLimit;

    let linePositionX: number;
    let linePositionY: number;

    // require 2 passes if a shadow; the first to draw the drop shadow, the second to draw the text
    const passesCount = style.dropShadow ? 2 : 1;

    // For v4, we drew text at the colours of the drop shadow underneath the normal text. This gave the correct zIndex,
    // but features such as alpha and shadowblur did not look right at all, since we were using actual text as a shadow.
    //
    // For v5.0.0, we moved over to just use the canvas API for drop shadows, which made them look much nicer and more
    // visually please, but now because the stroke is drawn and then the fill, drop shadows would appear on both the fill
    // and the stroke; and fill drop shadows would appear over the top of the stroke.
    //
    // For v5.1.1, the new route is to revert to v4 style of drawing text first to get the drop shadows underneath normal
    // text, but instead drawing text in the correct location, we'll draw it off screen (-paddingY), and then adjust the
    // drop shadow so only that appears on screen (+paddingY). Now we'll have the correct draw order of the shadow
    // beneath the text, whilst also having the proper text shadow styling.
    for (let i = 0; i < passesCount; ++i) {
      const isShadowPass = style.dropShadow && i === 0;
      // we only want the drop shadow, so put text way off-screen
      const dsOffsetText = isShadowPass ? Math.ceil(Math.max(1, height) + style.padding * 2) : 0;
      const dsOffsetShadow = dsOffsetText * this._resolution;

      if (isShadowPass) {
        // On Safari, text with gradient and drop shadows together do not position correctly
        // if the scale of the canvas is not 1: https://bugs.webkit.org/show_bug.cgi?id=197689
        // Therefore we'll set the styles to be a plain black whilst generating this drop shadow
        context.fillStyle = 'black';
        context.strokeStyle = 'black';

        const dropShadowColor = style.dropShadowColor;
        const rgb = utils.hex2rgb(
          typeof dropShadowColor === 'number' ? dropShadowColor : utils.string2hex(dropShadowColor),
        );
        const dropShadowBlur = style.dropShadowBlur * this._resolution;
        const dropShadowDistance = style.dropShadowDistance * this._resolution;

        context.shadowColor = `rgba(${rgb[0]! * 255},${rgb[1]! * 255},${rgb[2]! * 255},${style.dropShadowAlpha})`;
        context.shadowBlur = dropShadowBlur;
        context.shadowOffsetX = Math.cos(style.dropShadowAngle) * dropShadowDistance;
        context.shadowOffsetY = Math.sin(style.dropShadowAngle) * dropShadowDistance + dsOffsetShadow;
      } else {
        // set canvas text styles

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        context.fillStyle = this['_generateFillStyle'](style, lines, measured);
        // TODO: Can't have different types for getter and setter. The getter shouldn't have the number type as
        //       the setter converts to string. See this thread for more details:
        //       https://github.com/microsoft/TypeScript/issues/2521
        context.strokeStyle = this._generateStrokeStyle(style, lines, measured);

        context.shadowColor = 'black';
        context.shadowBlur = 0;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
      }

      let linePositionYShift = (lineHeight - fontProperties.fontSize) / 2;

      if (!Text.nextLineHeightBehavior || lineHeight - fontProperties.fontSize < 0) {
        linePositionYShift = 0;
      }

      // draw lines line by line
      for (let i = 0; i < lines.length; i++) {
        linePositionX = style.strokeThickness / 2;
        linePositionY = style.strokeThickness / 2 + i * lineHeight + fontProperties.ascent + linePositionYShift;

        if (style.align === 'right') {
          linePositionX += maxLineWidth - lineWidths[i]!;
        } else if (style.align === 'center') {
          linePositionX += (maxLineWidth - lineWidths[i]!) / 2;
        }

        if (style.stroke && style.strokeThickness) {
          this['drawLetterSpacing'](
            lines[i],
            linePositionX + style.padding,
            linePositionY + style.padding - dsOffsetText,
            true,
          );
        }

        if (style.fill) {
          this['drawLetterSpacing'](
            lines[i],
            linePositionX + style.padding,
            linePositionY + style.padding - dsOffsetText,
          );
        }
      }
    }

    this['updateTexture']();
  }
}

export type TextStyleStroke = string | string[] | CanvasGradient | CanvasPattern;

export default {};

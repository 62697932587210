import { FeatureState } from '../global.d';

export const gameRules1 = [
  { key: 'infoGameRules1_1', buyFeatureOnly: false },
  { key: 'infoGameRules1_2', buyFeatureOnly: false },
  { key: 'infoGameRules1_3', buyFeatureOnly: false },
  { key: 'infoGameRules1_4', buyFeatureOnly: false },
  { key: 'infoGameRules1_5', buyFeatureOnly: false },
];
export const gameRules2 = [
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_7', buyFeatureOnly: false },
  { key: 'infoGameRules1_8', buyFeatureOnly: false },
  { key: 'infoGameRules1_9', buyFeatureOnly: false },
];

export const infoFeatureBuy1 = [
  { key: 'infoFeatureBuyDesc_1' },
  { key: 'infoFeatureBuyDesc_2', value: FeatureState.NORMAL },
  { key: 'infoFeatureBuyDesc_3', value: FeatureState.MAX },
  { key: 'infoFeatureBuyDesc_4' },
];

import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { REEL_HEIGHT, REEL_WIDTH, eventManager } from '../config';

class ThrowTomato extends ViewContainer {
  private spine: SpineAnimation | undefined;
  public slot: SlotId | undefined;

  constructor() {
    super();
    this.pivot.set(0.5);

    eventManager.addListener(EventTypes.SET_IS_SLOTS_STOPPED, this.remove.bind(this));
  }

  public start(position: number, slot: SlotId) {
    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources['FTtomato']!.spineData);
    this.spine!.setAnimation('tomato_feature_in', false);
    this.spine!.addAnimation('tomato_feature_loop', true, 0);
    this.slot = slot;

    this.spine!.addOnStart(() => {
      this.addChild(this.spine!.getSpine());
      const delay = Tween.createDelayAnimation(300);
      delay.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.Tomato1 });
        AudioApi.play({ type: ISongs.Tomato1 });
      });
      delay.start();
    });

    this.position.set(
      (position % 6) * REEL_WIDTH + REEL_WIDTH / 2,
      Math.floor(position / 6) * REEL_HEIGHT + REEL_HEIGHT / 2,
    );

    this.spine!.start();
  }

  public end(songs: ISongs | undefined, nearmiss: boolean) {
    if (this.spine) {
      this.spine!.setAnimation('tomato_feature_out', false);

      const delay = nearmiss ? Tween.createDelayAnimation(300) : Tween.createDelayAnimation(800);
      delay.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.Tomato2 });
        AudioApi.play({ type: ISongs.Tomato2 });
        if (nearmiss) {
          const tomato2Delay = Tween.createDelayAnimation(400);
          tomato2Delay.addOnComplete(() => {
            AudioApi.stop({ type: ISongs.Tomato2 });
            AudioApi.play({
              type: ISongs.Tomato2,
            });
          });
          tomato2Delay.start();
        }
      });
      delay.start();

      this.spine!.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.Tomato3 });
        AudioApi.play({ type: ISongs.Tomato3 });

        if (songs != undefined) AudioApi.play({ type: songs });

        const delay = Tween.createDelayAnimation(0);
        delay.addOnComplete(() => {
          eventManager.emit(EventTypes.ADD_MULTIPLIER, this.slot!);
          eventManager.emit(EventTypes.PLAY_ANNOUNCE, this.slot!, this.position);
        });
        delay.start();

        this.remove();
      });
    }
  }

  public remove() {
    if (this.spine?.getSpine()) {
      const delay = Tween.createDelayAnimation(1);
      delay.addOnComplete(() => {
        this.removeChild(this.spine!.getSpine());
        destroySpine(this.spine!);
        this.spine = undefined;
      });
      delay.start();
    }
  }

  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
}

export default ThrowTomato;

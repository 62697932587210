import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isBaseGameMode, isBuyFeatureMode } from '../../utils';
import { GAME_TITLE_LOGO_X_POSITION, GAME_TITLE_LOGO_Y_POSITION, eventManager } from '../config';

class GameTitle extends PIXI.Container {
  private title: PIXI.Sprite;
  constructor() {
    super();

    this.title = this.initTitle();
    this.addChild(this.title);

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.setVisible.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.setVisible.bind(this));
  }

  private initTitle(): PIXI.Sprite {
    const title = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));
    title.anchor.set(0.5);
    title.position.set(GAME_TITLE_LOGO_X_POSITION + 15, GAME_TITLE_LOGO_Y_POSITION - 5);

    return title;
  }

  private setVisible(settings: { mode: GameMode }): void {
    if (isBaseGameMode(settings.mode) || isBuyFeatureMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
}

export default GameTitle;

import ViewContainer from '../components/container';

import ltfEffect from './ltfEffect';

class smallTomato extends ViewContainer {
  private tomato: ltfEffect | undefined;

  constructor() {
    super();
  }

  public start(multiplier: string, x: number, y: number) {
    this.tomato = new ltfEffect(multiplier, 0.6, true);
    this.tomato.position.set(x, y);
    this.tomato.pivot.set(0.5);
    this.addChild(this.tomato);

    this.tomato.start();
  }

  public remove() {
    this.tomato?.remove();
    this.removeChildren();
  }

  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
}

export default smallTomato;

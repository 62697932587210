import * as PIXI from 'pixi.js';
import { ITextStyle } from 'pixi.js';

import { Colors } from '../../config';
import { Variables } from '../../config/variables';
import { IGradientStrokeTextStyle } from '../components/gradientStrokeText';
import { LayeredTextStyleArray } from '../components/layeredText';

export const miniPayTableNumberStyle: Partial<ITextStyle> = {
  fontSize: 26,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xffffff',
  trim: true,
};

export const miniPayTableTextStyle: Partial<ITextStyle> = {
  fontSize: 26,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: Colors.GAME_COLOR,
  trim: true,
};

export const bottomContainerTextStyle = {
  default: {
    fontFamily: 'NotoSans-SemiCondensedBold',
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_LANDSCAPE_FIT_TO_WIDTH = 120;
export const FEATURE_BTN_TEXT_PORTRAIT_FIT_TO_WIDTH = 250;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 0; //-8;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;
export const FEATURE_BTN_TEXT_FONT_SIZE = 45;
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: FEATURE_BTN_TEXT_FONT_SIZE,
  lineHeight: 30,
  lineJoin: 'round',
  fill: ['0xFFF000'],
  stroke: '#000000',
  strokeThickness: 5,
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#505050',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: FEATURE_BTN_TEXT_FONT_SIZE + 1,
  lineHeight: 40,
  lineJoin: 'round',
  fill: '#fff000',
  stroke: '#60600000',
  strokeThickness: 5,
});
export const buyFeatureClickTextStyle = buyFeatureTextStyle;
export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  ...buyFeatureTextStyle,
  fill: '#383838',
  stroke: '#000000',
  strokeThickness: 5,
});

export const winMessage1: Partial<IGradientStrokeTextStyle> = {
  align: 'center',
  breakWords: true,
  fill: '#FFFFFF',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 120,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 5,
  padding: 4,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};
export const winLabelStyle: LayeredTextStyleArray = [
  {
    offset: [0, 0],
    style: {
      ...winMessage1,
      fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
      fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
      stroke: '#E00000',
      strokeThickness: 12,
      dropShadow: true,
      dropShadowColor: 0x700000,
      dropShadowAlpha: 1,
      dropShadowAngle: 0.785,
      dropShadowBlur: 0,
      dropShadowDistance: 4,
    },
  },
  {
    offset: [0, 0],
    style: {
      ...winMessage1,
      stroke: '#FFFFFF',
      strokeThickness: 18,
      dropShadow: true,
      dropShadowColor: '#ffffff',
      dropShadowAlpha: 1,
      dropShadowAngle: 0.785,
      dropShadowBlur: 0,
      dropShadowDistance: 4,
    },
  },
];

export const autoPlayCounterTextStyle = new PIXI.TextStyle({
  fontWeight: 'bold',
  fontFamily: Variables.FONT_FAMILY,
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
  lineJoin: 'round',
});

export const clockTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const gameNameTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const soundToastStyles = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

const baseCountUpStyle: Partial<PIXI.ITextStyle> = {
  align: 'center',
  breakWords: true,
  dropShadow: false,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: '#000000',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 100,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 2,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const countUpTextStyle: LayeredTextStyleArray = [
  {
    offset: [0, 0],
    style: {
      ...baseCountUpStyle,
      fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FFFF3C'],
      fillGradientStops: [0.1, 0.2, 0.48, 0.5, 0.7, 0.8],
      stroke: '#ffffff',
      strokeThickness: 4,
    },
  },
  {
    offset: [0, 0],
    style: {
      ...baseCountUpStyle,
      fill: '#000000',
      stroke: '#000000',
      strokeThickness: 14,
    },
  },
  {
    offset: [0, 0],
    style: {
      ...baseCountUpStyle,
      fill: '#ff0000',
      stroke: '#ff0000',
      strokeThickness: 16,
    },
  },
  {
    offset: [0, 0],
    style: {
      ...baseCountUpStyle,
      fill: '#ffffff',
      stroke: '#ffffff',
      strokeThickness: 24,
    },
  },
];

export const miniPayTableStyle = {
  fontSize: 28,
  lineHeight: 50,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
};

export const miniPayTableAdditionalTextStyle = new PIXI.TextStyle({
  fontSize: 28,
  lineHeight: 30,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
  wordWrap: true,
  wordWrapWidth: 220,
  breakWords: true,
  align: 'center',
});

export const laTomatinaStartTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 120,
  lineHeight: 30,
  lineJoin: 'round',
  fill: '#fffb00',
  stroke: '#000000',
  strokeThickness: 5,
});

export const laTomatinaTotalTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 100,
  lineHeight: 30,
  lineJoin: 'round',
  fill: '#fffb00',
  stroke: '#000000',
  strokeThickness: 5,
});

import * as PIXI from 'pixi.js';

import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

import ltfEffect from './ltfEffect';

class bigTomato extends PIXI.Sprite {
  private tomato: ltfEffect | undefined;
  constructor() {
    super();
  }

  public start(multiplier: string) {
    this.tomato = new ltfEffect(multiplier, 0.9, false);
    this.tomato.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    this.tomato.pivot.set(0.5);
    this.addChild(this.tomato);

    this.tomato.start();
  }

  public remove() {
    this.removeChildren();
  }

  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
}

export default bigTomato;

import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import ViewContainer from '../../components/container';
import { eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private reelBack = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelback));

  constructor() {
    super();

    this.reelBack.position.set(-4, -4);
    this.addChild(this.reelBack);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeMode.bind(this));
  }

  private changeMode(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_SPIN_LA_TOMATINA) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default ReelsBackgroundContainer;

import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setCurrentBonus } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { textExpansion } from '../../utils';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SPIN_COUNTER_LANDSCAPE_POSITION_X,
  SPIN_COUNTER_LANDSCAPE_POSITION_Y,
  SPIN_COUNTER_PORTRAIT_POSITION_X,
  SPIN_COUNTER_PORTRAIT_POSITION_Y,
  eventManager,
} from '../config';

import { countDownAlWaysTextStyle, countDownDisplayTextStyle } from './textStyles';

export class CountDownText extends ViewContainer {
  private bg: PIXI.Sprite;
  private countDownNumber: number;
  private text: PIXI.Text;
  private always: PIXI.Text;

  constructor() {
    super();

    this.countDownNumber = 3;

    this.bg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.panel));
    this.text = new PIXI.Text();
    this.always = new PIXI.Text();

    this.init();

    this.addChild(this.text);
    this.bg.addChild(this.always);
    this.addChild(this.bg);

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      (spins: number, curr: number, immediately?: boolean): void => this.countDownTextUpdate(spins, curr, immediately!),
    );

    eventManager.addListener(EventTypes.SET_COUNTDOWN, this.set.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeMode.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
  }

  private init(): void {
    this.bg.anchor.set(0.5);
    this.bg.position.set(SPIN_COUNTER_LANDSCAPE_POSITION_X, SPIN_COUNTER_LANDSCAPE_POSITION_Y);

    this.text.anchor.set(0.5);
    this.text.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    this.text.style = countDownDisplayTextStyle;
    this.text.text = this.countDownNumber;
    this.text.visible = false;

    this.always.anchor.set(0.5);
    this.always.position.set(0, 0);
    this.always.style = countDownAlWaysTextStyle;
    this.always.text = this.countDownNumber;
    this.visible = false;
  }

  private startAnimation(countdown: number) {
    AudioApi.play({ type: ISongs.TSF_countdown });
    this.text.text = countdown;
    this.always.text = countdown;
    this.text.visible = true;
    const delay = Tween.createDelayAnimation(1000);
    delay.addOnComplete(() => {
      this.text.visible = false;
    });
    delay.start();
  }

  private countDownTextUpdate(spins: number, currentSpin: number, immediately: boolean): void {
    this.countDownNumber = spins - currentSpin;

    if (immediately) return;

    this.startAnimation(this.countDownNumber);
  }

  private set(count: number, isExpand: boolean) {
    if (isExpand) {
      textExpansion(this.always);
      AudioApi.play({ type: ISongs.Indicator, stopPrev: true });
    }
    this.always.text = count;
  }

  private resize(width: number, height: number) {
    if (width > height) {
      this.bg.position.set(SPIN_COUNTER_LANDSCAPE_POSITION_X, SPIN_COUNTER_LANDSCAPE_POSITION_Y);
    } else {
      this.bg.position.set(SPIN_COUNTER_PORTRAIT_POSITION_X, SPIN_COUNTER_PORTRAIT_POSITION_Y);
    }
  }

  private changeMode(settings: { mode: GameMode }): void {
    if (
      settings.mode === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO ||
      settings.mode === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN
    ) {
      this.set(setCurrentBonus().rounds, false);
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
}
export default CountDownText;

import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { EventTypes, MessageBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import { SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { BANNER_POSITION_Y, WIN_MESSAGE_BANNER_DESCRIPTION_Y, WIN_MESSAGE_BANNER_TITLE_Y } from './config';
import { freeSpinsTotalWinTitleStyles, winTotalSpinsTextStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageBannerProps & {
    totalWin: string;
  };

  constructor(
    props: MessageBannerProps & {
      totalWin: string;
    },
  ) {
    super(props);
    this.props = props;
    this.x = SLOTS_CONTAINER_WIDTH / 2;
    this.y = BANNER_POSITION_Y;

    eventManager.emit(EventTypes.OPEN_MESSAGE_BANNER);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize2.bind(this));
    this.resize2(SlotMachine.getInstance().width, SlotMachine.getInstance().height);
    this.scale.set(SlotMachine.getInstance().gameView.scale.x, SlotMachine.getInstance().gameView.scale.y);
  }

  public override init(): MessageWinBanner {
    super.init();
    return this;
  }

  protected override initBanner(): PIXI.Sprite {
    const winBanner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.winBanner));
    winBanner.anchor.set(0.5, 0.5);
    return winBanner;
  }

  protected override initTitle(titleText?: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText || '', freeSpinsTotalWinTitleStyles);
    winTitle.visible = !!winTitle;

    winTitle.anchor.set(0.5, 0.5);
    winTitle.y = WIN_MESSAGE_BANNER_TITLE_Y;
    winTitle.x = 0;
    updateTextScale(winTitle, 480, 180);
    return winTitle;
  }

  protected override initDescription(_descriptionText?: string): PIXI.Text {
    const winTotalSpins = new PIXI.Text(`${this.props.totalWin}`, winTotalSpinsTextStyles);
    winTotalSpins.visible = !!winTotalSpins;

    winTotalSpins.anchor.set(0.5, 0.5);
    winTotalSpins.y = WIN_MESSAGE_BANNER_DESCRIPTION_Y;
    updateTextScale(winTotalSpins, 800, 250);
    winTotalSpins.x = 0;
    return winTotalSpins;
  }

  protected override initBtn(_btnText?: string): PIXI.Text {
    const btn = new PIXI.Text('');

    return btn;
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    if (this.visible) {
      this.scale.set(scale);
    }
  }

  private resize2(width: number, height: number) {
    if (this.visible) {
      this.x = width / 2;
      this.y = height / 2;
      if (width > height) {
        this.pivot.y = -60;
      } else {
        this.pivot.y = 110;
      }
    }
  }
}

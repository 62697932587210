import { EventTypes, GameMode } from '../../global.d';
import { setBetAmount, setCoinAmount, setCoinValue, setCurrency, setGameMode, setSlotConfig } from '../../gql/cache';
import SpineAnimation from '../../slotMachine/animations/spine';
import { eventManager } from '../../slotMachine/config';
import { Icon } from '../../slotMachine/d';

import { nextTick } from './../utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lineSet.coinAmountMultiplier)) / 100
  );
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const ById = (icons: Icon[], id: string): Icon => {
  if (id === undefined) {
    id = 'A';
  }

  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  throw error;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const destroySpine = (spine: SpineAnimation): void => {
  nextTick(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy({ children: true });
    }
  });
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotStopped,
  isInTransition,
  isCountUp,
  isBuyFeaturePopupOpened,
  isAutoPlay,
  isFadeOut,
  isBrokenBuyFeature,
  isTomatina,
  isPhoenix,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isInTransition: boolean;
  isCountUp: boolean;
  isBuyFeaturePopupOpened: boolean;
  isAutoPlay: boolean;
  isFadeOut: boolean;
  isBrokenBuyFeature: boolean;
  isTomatina: boolean;
  isPhoenix: boolean;
}): boolean => {
  if (isPhoenix) {
    return false;
  }
  if (isBrokenBuyFeature) {
    return false;
  }

  if (isAutoPlay) {
    return false;
  }

  if (isCountUp) {
    return true;
  }

  if (isTomatina) {
    return false;
  }

  if (isInTransition) {
    return false;
  }

  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  if (isFadeOut) {
    return false;
  }

  if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
    return false;
  }
  return true;
};

/**
 * ⚠️CAUTION⚠️
 * This function ignores null checks and is unsafe
 * @param value Nullable value
 * @returns value typecast to NonNullable
 */
export function getNonNullableValue<T>(value: T): RecursiveNonNullable<T> {
  return value as RecursiveNonNullable<T>;
}

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0; // return 0 if BE send empty arr []
};

// updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
export const updateCoinValueAfterBonuses = (): void => {
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode, setWildAmount } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { textExpansion } from '../../utils';
import { eventManager } from '../config';

import { tomatoCountTextStyle } from './textStyle';

class TomatoCount extends PIXI.Container {
  private tomatoAmount: number;
  private text: PIXI.Text;
  constructor() {
    super();

    this.tomatoAmount = setWildAmount();
    const bg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.tomatospinFeatureWildIndicator));
    bg.anchor.set(0.5);
    bg.position.set(-480, 0);

    const tomato = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolWild));
    tomato.anchor.set(0.5);
    tomato.x = -80;
    bg.addChild(tomato);
    this.addChild(bg);

    this.text = new PIXI.Text();
    this.text.anchor.set(0.5);
    this.text.position.set(-406, 0);
    this.text.text = 'x' + this.tomatoAmount;
    this.text.style = tomatoCountTextStyle;
    this.addChild(this.text);

    eventManager.addListener(EventTypes.ADD_WILD, this.addTomato.bind(this));
    eventManager.addListener(EventTypes.RESET_WILD, this.resetTomato.bind(this));
    eventManager.addListener(EventTypes.SET_WILD, this.setTomato.bind(this));
  }

  private addTomato(amount: number) {
    this.tomatoAmount += amount;
    this.text.text = 'x' + this.tomatoAmount;
  }

  private resetTomato() {
    this.tomatoAmount = 0;
    this.text.text = 'x' + this.tomatoAmount;
  }

  private setTomato(isExpand: boolean) {
    if (
      setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO ||
      setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN
    ) {
      const newTomatoAmount = setWildAmount();
      if (this.tomatoAmount != newTomatoAmount && isExpand) {
        textExpansion(this.text);
        AudioApi.play({ type: ISongs.Indicator });
      }
      this.tomatoAmount = newTomatoAmount;
      this.text.text = 'x' + this.tomatoAmount;
    }
  }
}

export default TomatoCount;

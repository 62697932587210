import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs } from '../../config';
import { EventTypes, GameMode, MessageBannerProps } from '../../global.d';
import { setGameMode, setIsOpenedMessageBanner } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

import {
  BANNER_POSITION_Y,
  MESSAGE_BANNER_BTN_SCALE,
  MESSAGE_BANNER_BTN_X,
  MESSAGE_BANNER_BTN_Y,
  MESSAGE_BANNER_DESCRIPTION_X,
  MESSAGE_BANNER_DESCRIPTION_Y,
  MESSAGE_BANNER_TITLE2_Y,
  MESSAGE_BANNER_TITLE_SCALE,
  MESSAGE_BANNER_TITLE_X,
  MESSAGE_BANNER_TITLE_Y,
} from './config';
import { btnTextStyle, descriptionTextStyle, titleTextStyle } from './textStyles';

class MessageBanner extends ViewContainer {
  public banner: PIXI.Sprite;

  private title!: PIXI.Text;

  private title2?: PIXI.Text;

  private description!: PIXI.Text;

  private btn!: PIXI.Text;

  constructor(props: MessageBannerProps) {
    super();
    this.x = SLOTS_CONTAINER_WIDTH / 2;
    this.y = BANNER_POSITION_Y;
    this.pivot.y = -60;
    this.visible = true;
    this.interactive = true;
    this.on('click', () => {
      this.transFreeSpin(props);
    });
    this.on('touchstart', () => {
      this.transFreeSpin(props);
    });
    eventManager.addListener(EventTypes.BACK_DROP_CLICK, () => {
      this.transFreeSpin(props);
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      this.transFreeSpin(props);
    });
    this.banner = this.initBanner();
    this.addChild(this.banner);
    this.initText(props);
    this.init();
    eventManager.emit(EventTypes.OPEN_MESSAGE_BANNER);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize2.bind(this));
    if (SlotMachine.getInstance() != undefined) {
      this.resize2(SlotMachine.getInstance().width, SlotMachine.getInstance().height);
      this.scale.set(SlotMachine.getInstance().gameView.scale.x, SlotMachine.getInstance().gameView.scale.y);
    }
    setIsOpenedMessageBanner(true);
  }

  private transFreeSpin(props: MessageBannerProps) {
    setIsOpenedMessageBanner(false);
    if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) {
      this.tomatoAndSpinStart(props);
    } else if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
      this.tomatoAndSpinSpinPart(props);
    } else {
      this.laTomatinaStart(props);
    }
  }

  private tomatoAndSpinSpinPart(props: MessageBannerProps): void {
    if (props.callback) {
      props.callback();
      props.callback = undefined;
    }
    if (!props.preventDefaultDestroy) this.handleDestroy();
  }

  private tomatoAndSpinStart(props: MessageBannerProps): void {
    if (props.callback) {
      props.callback();
      props.callback = undefined;
    }
    if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) eventManager.emit(EventTypes.TOMATO_SPIN);
    if (!props.preventDefaultDestroy) this.handleDestroy();
  }

  private laTomatinaStart(props: MessageBannerProps): void {
    AudioApi.play({ type: ISongs.LTF_start });
    if (props.callback) {
      props.callback();
      props.callback = undefined;
    }
    if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
      eventManager.emit(EventTypes.LATOMATINA);
    }
    if (!props.preventDefaultDestroy) this.handleDestroy();
  }

  private init(): void {
    this.addChild(this.title);
    if (this.title2 != undefined) this.addChild(this.title2);
    if (this.description != undefined) this.addChild(this.description);
    this.addChild(this.btn);
  }

  private initText(props: MessageBannerProps): void {
    this.title = this.initTitle(props.title);
    if (props.title2 != undefined) this.title2 = this.initTitle2(props.title2);
    if (props.subTitle != undefined) this.description = this.initDescription(props.subTitle);
    this.btn = this.initBtn(props.btnText);
  }

  private initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.featureBanner));
    banner.anchor.set(0.5, 0.5);
    return banner;
  }

  private initTitle(titleText?: string): PIXI.Text {
    const title = new PIXI.Text(titleText, titleTextStyle);
    title.visible = !!titleText;
    title.anchor.set(0.5);
    title.position.set(MESSAGE_BANNER_TITLE_X, MESSAGE_BANNER_TITLE_Y);
    title.scale.set(MESSAGE_BANNER_TITLE_SCALE);
    updateTextScale(title, this.banner.width - 170, 200);
    return title;
  }

  private initTitle2(titleText?: string): PIXI.Text {
    const title = new PIXI.Text(titleText, titleTextStyle);
    title.visible = !!titleText;
    title.anchor.set(0.5);
    title.position.set(MESSAGE_BANNER_TITLE_X, MESSAGE_BANNER_TITLE2_Y);
    title.scale.set(MESSAGE_BANNER_TITLE_SCALE);
    updateTextScale(title, this.banner.width - 170, 200);
    title.scale.set(this.title.scale.x, this.title.scale.y);
    return title;
  }

  private initDescription(descriptionText?: string): PIXI.Text {
    const description = new PIXI.Text(descriptionText, descriptionTextStyle);
    description.visible = !!descriptionText;
    description.anchor.set(0.5);
    description.position.set(MESSAGE_BANNER_DESCRIPTION_X, MESSAGE_BANNER_DESCRIPTION_Y);
    updateTextScale(description, this.banner.width - 130, 200);
    return description;
  }

  private initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.anchor.set(0.5);
    btn.position.set(MESSAGE_BANNER_BTN_X, MESSAGE_BANNER_BTN_Y);
    btn.scale.set(MESSAGE_BANNER_BTN_SCALE);
    updateTextScale(btn, this.banner.width - 200, 200);
    return btn;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    eventManager.removeListener(EventTypes.BACK_DROP_CLICK);
    this.destroy({ children: true, texture: false, baseTexture: false });
    this.visible = false;
    eventManager.emit(EventTypes.CLOSE_MESSAGE_BANNER);
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    if (this.visible) {
      this.scale.set(scale);
    }
  }

  private resize2(width: number, height: number) {
    if (this.visible) {
      this.x = width / 2;
      this.y = height / 2;
      if (width > height) {
        this.pivot.y = -60;
      } else {
        this.pivot.y = 110;
      }
    }
  }
}

export default MessageBanner;

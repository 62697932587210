import { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const tomatoCountTextStyle: Partial<ITextStyle> = {
  fontSize: 80,
  fontFamily: font,
  align: 'center',
  fill: '#ff1400',
  stroke: '#ffffff',
  fontWeight: 'bold',
  lineHeight: 20,
  strokeThickness: 10,
};

export const wayMultiplierTextStyle: Partial<ITextStyle> = {
  fontSize: 60,
  fontFamily: font,
  align: 'center',
  fontWeight: 'bold',
  lineHeight: 20,
  stroke: '#ffffff',
  strokeThickness: 4,
};

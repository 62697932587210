import { Container } from 'pixi.js';

import { EventTypes, GameMode, UserBonus } from '../../global.d';
import { eventManager } from '../config';

export class ViewContainer extends Container {
  constructor() {
    super();
    this.visible = true;
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  protected onModeChange(_settings: { mode: GameMode }): void {}

  protected onBrokenGame(_bonus: UserBonus): void {}

  protected resize(_width: number, _height: number): void {}
}

import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import {
  PAY_TABLE_IMG_ADJUST,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_RESOURCE_HEIGHT,
  eventManager,
} from '../config/index';
import { Icon } from '../d';
import { Slot } from '../slot/slot';

import MiniPayTable from './miniPayTable';

class MiniPayTableContainer extends PIXI.Container {
  private getSlotById: (id: number) => Slot | null;

  private payTableContainers: PIXI.Container<MiniPayTable>[];

  constructor(icons: Icon[], getSlotByIdFn: (id: number) => Slot | null) {
    super();
    this.getSlotById = getSlotByIdFn;
    this.visible = true;
    eventManager.addListener(EventTypes.DISABLE_PAY_TABLE, (isVisible: boolean) => (this.visible = isVisible));
    this.payTableContainers = [];

    this.createContainers(icons);
    this.position.y = -25;
  }

  private createContainers(icons: Icon[]) {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const slot = this.getSlotById(index)!;
        const icon = icons.find((icon) => icon.id === slot.slotId)!;
        const container = new PIXI.Container<MiniPayTable>();
        const rect = new PIXI.Rectangle(0, (SLOT_RESOURCE_HEIGHT - SLOT_HEIGHT) / 2, REEL_WIDTH, SLOT_HEIGHT);
        container.x = this.xPosition(col);
        container.y = this.yPosition(row);
        container.interactive = true;
        container.hitArea = rect;

        container.on('mousedown', () => {
          eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
        });
        container.on('touchstart', () => eventManager.emit(EventTypes.SHOW_PAY_TABLE, index));
        const payTable = new MiniPayTable(index, icon);
        container.addChild(payTable);
        this.payTableContainers.push(container);
        this.addChild(container);
      }
    }
  }

  private xPosition(col: number): number {
    return col * REEL_WIDTH + PAY_TABLE_IMG_ADJUST;
  }

  private yPosition(row: number): number {
    return row * SLOT_HEIGHT;
  }

  public setSpinResult(spinResult: Icon[]): void {
    spinResult.forEach((icon, index: number) => {
      const container = this.payTableContainers[index]!;
      const payTable = container.children[0]!;
      payTable.setPayTableData(icon);
    });
  }
}

export default MiniPayTableContainer;

import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { Arrow, EventTypes, GameMode } from '../../global.d';
import { setGameMode, setIsForceStop, setMultiplier } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { isWay } from '../../utils';
import { GAME_TITLE_LOGO_Y_POSITION, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

import TomatoCount from './tomatoCount';
import Way from './way';

class WayIndicatorBase extends PIXI.Container {
  private base: PIXI.Sprite;
  private way: Way[] = [];
  constructor() {
    super();

    this.base = this.initBase();
    this.addChild(this.base);

    this.SetAllMultiplier(false);

    eventManager.addListener(EventTypes.SET_ALL_MULTIPLIER, this.SetAllMultiplier.bind(this));

    eventManager.addListener(EventTypes.ADD_MULTIPLIER, this.addMultiplier.bind(this));

    eventManager.addListener(EventTypes.RESET_MULTIPLIER, this.resetMultiplier.bind(this));

    eventManager.addListener(EventTypes.RESET_ALL_MULTIPLIER, () => {
      this.way.forEach((i) => {
        i.resetMultiplier();
      });
    });

    eventManager.addListener(EventTypes.SET_IDLE_MULTIPLIER, this.synchronizeIdle.bind(this));

    eventManager.addListener(EventTypes.PAYLINE_EFFECT, (arrow) => {
      this.way[arrow]!.payLineEffect();
    });

    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.TOMATO_DELETE, this.showAmount.bind(this));
  }

  private changeMode(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_SPIN_LA_TOMATINA) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private initBase(): PIXI.Sprite {
    const base = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.wayIndicatorBase));
    base.anchor.set(0.5);
    base.position.set(SLOTS_CONTAINER_WIDTH - base.width / 2 + 22, GAME_TITLE_LOGO_Y_POSITION - 12);

    this.way[Arrow.RIGHT] = new Way('right', -235, 0, 1);
    this.way[Arrow.LEFT] = new Way('left', -75, 0, 0);
    this.way[Arrow.UP] = new Way('up', 85, 0, 0);
    this.way[Arrow.DOWN] = new Way('down', 245, 0, 0);

    this.way.forEach((i) => {
      base.addChild(i);
    });

    const tomato = new TomatoCount();
    base.addChild(tomato);

    return base;
  }

  private SetAllMultiplier(isExpand: boolean) {
    setMultiplier().forEach((value, index) => {
      this.way[index]!.changeMultiplier(value, isExpand);
    });
  }

  private synchronizeIdle() {
    this.way.forEach((i) => {
      i.setIdle();
    });
  }

  private addMultiplier(slot: SlotId) {
    if (isWay(slot) != -1) {
      this.way[isWay(slot)]!.addMultiplier(1, false, false);
    }
  }

  private resetMultiplier(arrow: Arrow) {
    this.way[arrow]!.resetMultiplier();
  }

  private showAmount() {
    if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO || setIsForceStop()) {
      eventManager.emit(EventTypes.SET_WILD, true);
      eventManager.emit(EventTypes.SET_ALL_MULTIPLIER, true);
    }
  }
}

export default WayIndicatorBase;

import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import { createZoomAnimation } from '../animations/helper';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { LayeredText } from '../components/layeredText';
import {
  BIG_WIN_END_DURATION,
  BIG_WIN_TITLE,
  BIG_WIN_TITLE_SCALE,
  BIG_WIN_ZOOM_TITLE_DURATION,
  EPIC_WIN_TITLE,
  EPIC_WIN_TITLE_SCALE,
  EPIC_WIN_ZOOM_TITLE_DURATION,
  GREAT_WIN_TITLE,
  GREAT_WIN_TITLE_SCALE,
  GREAT_WIN_ZOOM_TITLE_DURATION,
  MEGA_WIN_TITLE,
  MEGA_WIN_TITLE_SCALE,
  MEGA_WIN_ZOOM_TITLE_DURATION,
  SLOTS_CONTAINER_WIDTH,
  WinStages,
  eventManager,
  winLabelStyle,
} from '../config';

class WinLabelContainer extends ViewContainer {
  public bigWinLabel: LayeredText;

  public megaWinLabel: LayeredText;

  public greatWinLabel: LayeredText;

  public epicWinLabel: LayeredText;

  private bigWinAnimation: Animation | null = null;

  private megaWinAnimation: Animation | null = null;

  private greatWinAnimation: Animation | null = null;

  private epicWinAnimation: Animation | null = null;

  private bigWinFadeOutAnimation: Animation | null = null;

  private megaWinFadeOutAnimation: Animation | null = null;

  private greatWinFadeOutAnimation: Animation | null = null;

  private epicWinFadeOutAnimation: Animation | null = null;

  constructor() {
    super();
    this.bigWinLabel = this.initBigWin();
    this.megaWinLabel = this.initMegaWin();
    this.greatWinLabel = this.initGreatWin();
    this.epicWinLabel = this.initEpicWin();

    eventManager.addListener(EventTypes.SET_BIG_WIN_VISIBILITY, this.setBigWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_MEGA_WIN_VISIBILITY, this.setMegaWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_GREAT_WIN_VISIBILITY, this.setGreatWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_EPIC_WIN_VISIBILITY, this.setEpicWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SKIP_ALL_WIN_ANIMATIONS, this.skipAllWinAnimations.bind(this));
    eventManager.addListener(EventTypes.HANDLE_START_FADE_ANIMATION, (stage: number) => this.startFade(stage));
    eventManager.addListener(EventTypes.HANDLE_SKIP_FADE_ANIMATION, () => this.skipFadeAnimation());
  }

  private initBigWin(): LayeredText {
    const bigWin = new LayeredText(BIG_WIN_TITLE, winLabelStyle);
    bigWin.position.set(SLOTS_CONTAINER_WIDTH / 2, 33);
    bigWin.visible = false;
    this.addChild(bigWin);
    return bigWin;
  }

  private initMegaWin(): LayeredText {
    const megaWin = new LayeredText(MEGA_WIN_TITLE, winLabelStyle);
    megaWin.position.set(SLOTS_CONTAINER_WIDTH / 2, 33);
    megaWin.visible = false;
    this.addChild(megaWin);
    return megaWin;
  }

  private initGreatWin(): LayeredText {
    const greatWin = new LayeredText(GREAT_WIN_TITLE, winLabelStyle);
    greatWin.position.set(SLOTS_CONTAINER_WIDTH / 2, 33);
    greatWin.visible = false;
    this.addChild(greatWin);
    return greatWin;
  }

  private initEpicWin(): LayeredText {
    const epicWin = new LayeredText(EPIC_WIN_TITLE, winLabelStyle);
    epicWin.position.set(SLOTS_CONTAINER_WIDTH / 2, 33);
    epicWin.visible = false;
    this.addChild(epicWin);
    return epicWin;
  }

  private setBigWinVisibility(visible: boolean) {
    this.bigWinLabel.visible = visible;
    if (visible) {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = createZoomAnimation(
        this.bigWinLabel,
        BIG_WIN_TITLE_SCALE,
        BIG_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.bigWinAnimation!.start();
    } else {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = null;
    }
  }

  private setMegaWinVisibility(visible: boolean) {
    this.megaWinLabel.visible = visible;
    if (visible) {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = createZoomAnimation(
        this.megaWinLabel,
        MEGA_WIN_TITLE_SCALE,
        MEGA_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.megaWinAnimation!.start();
    } else {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = null;
    }
  }

  private setGreatWinVisibility(visible: boolean) {
    this.greatWinLabel.visible = visible;
    if (visible) {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = createZoomAnimation(
        this.greatWinLabel,
        GREAT_WIN_TITLE_SCALE,
        GREAT_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.greatWinAnimation!.start();
    } else {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = null;
    }
  }

  private setEpicWinVisibility(visible: boolean) {
    this.epicWinLabel.visible = visible;
    if (visible) {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = createZoomAnimation(
        this.epicWinLabel,
        EPIC_WIN_TITLE_SCALE,
        EPIC_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.epicWinAnimation!.start();
    } else {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = null;
    }
  }

  private skipAllWinAnimations() {
    this.setBigWinVisibility(false);
    this.setMegaWinVisibility(false);
    this.setGreatWinVisibility(false);
    this.setEpicWinVisibility(false);
  }

  private skipFadeAnimation() {
    this.bigWinFadeOutAnimation?.skip();
    this.megaWinFadeOutAnimation?.skip();
    this.greatWinFadeOutAnimation?.skip();
    this.epicWinFadeOutAnimation?.skip();
  }

  private startFade(stage: WinStages) {
    if (stage === WinStages.BigWin) {
      this.bigWinFadeOutAnimation = this.createFadeAnimation(this.bigWinLabel);
      const onEnd = () => {
        this.bigWinLabel.visible = false;
        this.bigWinLabel.alpha = 1;
        this.bigWinFadeOutAnimation = null;
      };
      this.bigWinFadeOutAnimation!.addOnSkip(onEnd);
      this.bigWinFadeOutAnimation!.addOnComplete(onEnd);
      this.bigWinFadeOutAnimation!.start();
    }
    if (stage === WinStages.MegaWin) {
      this.megaWinFadeOutAnimation = this.createFadeAnimation(this.megaWinLabel);
      const onEnd = () => {
        this.megaWinLabel.visible = false;
        this.megaWinLabel.alpha = 1;
        this.megaWinFadeOutAnimation = null;
      };
      this.megaWinFadeOutAnimation!.addOnSkip(onEnd);
      this.megaWinFadeOutAnimation!.addOnComplete(onEnd);
      this.megaWinFadeOutAnimation!.start();
    }
    if (stage === WinStages.GreatWin) {
      this.greatWinFadeOutAnimation = this.createFadeAnimation(this.greatWinLabel);
      const onEnd = () => {
        this.greatWinLabel.visible = false;
        this.greatWinLabel.alpha = 1;
        this.greatWinFadeOutAnimation = null;
      };
      this.greatWinFadeOutAnimation!.addOnSkip(onEnd);
      this.greatWinFadeOutAnimation!.addOnComplete(onEnd);
      this.greatWinFadeOutAnimation!.start();
    }
    if (stage === WinStages.EpicWin) {
      this.epicWinFadeOutAnimation = this.createFadeAnimation(this.epicWinLabel);
      const onEnd = () => {
        this.epicWinLabel.visible = false;
        this.epicWinLabel.alpha = 1;
        this.epicWinFadeOutAnimation = null;
      };
      this.epicWinFadeOutAnimation!.addOnSkip(onEnd);
      this.epicWinFadeOutAnimation!.addOnComplete(onEnd);
      this.epicWinFadeOutAnimation!.start();
    }
  }

  private createFadeAnimation(target: LayeredText) {
    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: target,
      property: TweenProperties.ALPHA,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      duration: BIG_WIN_END_DURATION,
    });
    fadeOutAnimation.addOnComplete(() => {
      target.visible = false;
      target.alpha = 1;
    });
    return fadeOutAnimation;
  }
}

export default WinLabelContainer;

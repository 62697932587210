import { Container } from 'pixi.js';

import { GradientStrokeText, IGradientStrokeTextStyle } from './gradientStrokeText';

interface LayeredTextStyle {
  offset?: [number, number];
  style: Partial<IGradientStrokeTextStyle>;
}

export type LayeredTextStyleArray = LayeredTextStyle[];

export class LayeredText extends Container<GradientStrokeText> {
  private _text: string;

  private styles: LayeredTextStyleArray;

  constructor(text: string, styles: LayeredTextStyleArray) {
    super();
    this._text = text;
    this.styles = [...styles].reverse();

    this.styles.forEach((style) => {
      const obj = new GradientStrokeText(text, style.style);
      obj.anchor.set(0.5);
      if (style.offset) obj.position.set(...style.offset);
      this.addChild(obj);
    });
  }

  public get text(): string {
    return this._text;
  }

  public set text(text: string) {
    this._text = text;
    this.children.forEach((child) => {
      child.text = text;
    });
  }
}

export default {};

import _ from 'lodash';

import type { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs { // 20240119 update
  Scatter_01 = '004_06_Scatter_01',
  Scatter_02 = '004_07_Scatter_02',
  Scatter_03 = '004_08_Scatter_03',
  Scatter_04 = '004_09_Scatter_04',
  FeatureReTrigger = '004_13_FeatureReTrigger',
  Yocho_Phoenix = '010_38_Yocho_Phoenix',
  BaseGame_BGM_Base = '023_01_BaseGame_BGM_Base',
  BaseGame_BGM_Melo = '023_02_BaseGame_BGM_Melo',
  LA_TOMATINA_BGM_Loop = '023_04_LA_TOMATINA_BGM_Loop',
  TotalWinBanner_LTF = '023_05_TotalWinBanner_LTF',
  TOMATO_BGM_Loop = '023_07_TOMATO_BGM_Loop',
  SPIN_BGM_Loop = '023_09_SPIN_BGM_Loop',
  TotalWinBanner_TSF = '023_10_TotalWinBanner_TSF',
  BigWin_Loop = '023_11_BigWin_Loop',
  BigWin_END = '023_12_BigWin_END',
  Truck1 = '023_13_Truck1',
  Truck2 = '023_14_Truck2',
  Truck3 = '023_15_Truck3',
  Tomato1 = '023_16_Tomato1',
  Tomato2 = '023_17_Tomato2',
  Tomato3 = '023_18_Tomato3',
  Effect_payout = '023_19_effect_payout',
  Indicator = '023_20_indicator',
  Effect_move1 = '023_21_effect_move1',
  Effect_move2 = '023_22_effect_move2',
  LTF_start = '023_23_LTF_start',
  LTF_tomatoMP = '023_24_LTF_tomatoMP',
  LTF_end = '023_25_LTF_end',
  TSF_countdown = '023_26_TSF_countdown',
  TSF_symbols = '023_27_TSF_symbols',
  TSF_transition = '023_28_TSF_transition',
  SFX_BuyFeature = 'SFX_BuyFeature',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_FeatureTrigger = 'SFX_WIN_FeatureTrigger',
  SFX_Win_Big = 'SFX_Win_Big',
  SFX_Win_Epic = 'SFX_Win_Epic',
  SFX_Win_Great = 'SFX_Win_Great',
  SFX_Win_Mega = 'SFX_Win_Mega',
}

export enum ISongsVoice {}

export const audioSprite: TAudioSprite = {
  [ISongs.Scatter_01]: [0, 175.71428571428572],
  [ISongs.Scatter_02]: [2000, 162.40362811791397],
  [ISongs.Scatter_03]: [4000, 502.5623582766441],
  [ISongs.Scatter_04]: [6000, 519.3877551020405],
  [ISongs.FeatureReTrigger]: [8000, 433.3333333333336],
  [ISongs.Yocho_Phoenix]: [10000, 4033.333333333333],
  [ISongs.BaseGame_BGM_Base]: [16000, 77419.38775510204, true],
  [ISongs.BaseGame_BGM_Melo]: [95000, 77419.38775510204, true],
  [ISongs.LA_TOMATINA_BGM_Loop]: [174000, 36000, true],
  [ISongs.TotalWinBanner_LTF]: [211000, 3913.6734693877597],
  [ISongs.TOMATO_BGM_Loop]: [216000, 33230.77097505669, true],
  [ISongs.SPIN_BGM_Loop]: [251000, 41600.00000000002, true],
  [ISongs.TotalWinBanner_TSF]: [294000, 4244.444444444469],
  [ISongs.BigWin_Loop]: [300000, 68031.51927437642, true],
  [ISongs.BigWin_END]: [370000, 5323.92290249436],
  [ISongs.Truck1]: [377000, 457.5963718820617],
  [ISongs.Truck2]: [379000, 1876.5532879818352],
  [ISongs.Truck3]: [382000, 755.1473922902687],
  [ISongs.Tomato1]: [384000, 372.33560090703577],
  [ISongs.Tomato2]: [386000, 535.2607709750714],
  [ISongs.Tomato3]: [388000, 607.1428571428328],
  [ISongs.Effect_payout]: [390000, 439.00226757369865],
  [ISongs.Indicator]: [392000, 917.0068027210618],
  [ISongs.Effect_move1]: [394000, 1698.50340136054],
  [ISongs.Effect_move2]: [397000, 2042.154195011335],
  [ISongs.LTF_start]: [401000, 232.72108843536898],
  // [ISongs. LTF_tomatoMP]: [403000,887.1882086168057],
  [ISongs.LTF_tomatoMP]: [402990, 887.1882086168057],
  // ↑ Lag at sound start causes sound crackling, which is avoided by delaying the sound by 0.1f.
  [ISongs.LTF_end]: [405000, 1600.4308390022857],
  [ISongs.TSF_countdown]: [408000, 1389.4784580498936],
  [ISongs.TSF_symbols]: [411000, 1407.1882086167875],
  [ISongs.TSF_transition]: [414000, 1028.5941043084108],
  [ISongs.SFX_BuyFeature]: [417000, 666.6666666666856],
  [ISongs.SFX_SM_CountUp_End]: [419000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [421000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [423000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [425000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [427000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [429000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [431000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [433000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [435000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [437000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [439000, 151.02040816327644],
  [ISongs.SFX_WIN_FeatureTrigger]: [441000, 879.841269841279],
  [ISongs.SFX_Win_Big]: [443000, 1622.3356009070358],
  [ISongs.SFX_Win_Epic]: [446000, 1862.2675736961583],
  [ISongs.SFX_Win_Great]: [449000, 2118.390022675726],
  [ISongs.SFX_Win_Mega]: [453000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.Scatter_01]: 0.3,
  [ISongs.Scatter_02]: 0.3,
  [ISongs.Scatter_03]: 0.3,
  [ISongs.Scatter_04]: 0.3,
  [ISongs.FeatureReTrigger]: 0.3,
  [ISongs.Yocho_Phoenix]: 0.3,
  [ISongs.BaseGame_BGM_Base]: 0.3,
  [ISongs.BaseGame_BGM_Melo]: 0.2,
  [ISongs.LA_TOMATINA_BGM_Loop]: 0.3,
  [ISongs.TotalWinBanner_LTF]: 0.3,
  [ISongs.TOMATO_BGM_Loop]: 0.3,
  [ISongs.SPIN_BGM_Loop]: 0.3,
  [ISongs.TotalWinBanner_TSF]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_END]: 0.3,
  [ISongs.Truck1]: 0.3,
  [ISongs.Truck2]: 0.3,
  [ISongs.Truck3]: 0.3,
  [ISongs.Tomato1]: 0.3,
  [ISongs.Tomato2]: 0.3,
  [ISongs.Tomato3]: 0.3,
  [ISongs.Effect_payout]: 0.3,
  [ISongs.Indicator]: 0.3,
  [ISongs.Effect_move1]: 0.3,
  [ISongs.Effect_move2]: 0.3,
  [ISongs.LTF_start]: 0.9,
  [ISongs.LTF_tomatoMP]: 0.3,
  [ISongs.LTF_end]: 0.3,
  [ISongs.TSF_countdown]: 0.3,
  [ISongs.TSF_symbols]: 0.3,
  [ISongs.TSF_transition]: 0.3,
  [ISongs.SFX_BuyFeature]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.3,
  [ISongs.SFX_UI_Close]: 0.3,
  [ISongs.SFX_UI_General]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.3,
  [ISongs.SFX_UI_MaxBet]: 0.3,
  [ISongs.SFX_UI_MenuOpen]: 0.3,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.3,
  [ISongs.SFX_Win_Big]: 0.3,
  [ISongs.SFX_Win_Epic]: 0.3,
  [ISongs.SFX_Win_Great]: 0.3,
  [ISongs.SFX_Win_Mega]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, audioSpriteVolume, mappedAudioSprites } from '../../config';
import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setCurrentStage, setGameMode } from '../../gql/cache';
import { BGM_FADE_OUT_VOLUME, SlotMachineState, eventManager } from '../../slotMachine/config';
import { isFreeSpinsMode } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';

type BgmType = Record<BgmSoundTypes, { intro: ISongs | undefined; base: ISongs; melo?: ISongs }>;
export const bgmList: BgmType = {
  regular: {
    intro: undefined,
    base: ISongs.BaseGame_BGM_Base,
    melo: ISongs.BaseGame_BGM_Melo,
  },
  LA_TOMATINA: {
    intro: undefined,
    base: ISongs.LA_TOMATINA_BGM_Loop,
  },
  TOMATO_SPIN_TOMATO: {
    intro: undefined,
    base: ISongs.TOMATO_BGM_Loop,
  },
  TOMATO_SPIN_SPIN: {
    intro: undefined,
    base: ISongs.SPIN_BGM_Loop,
  },
};

class BgmControl {
  private bgmListIndex: BgmSoundTypes | undefined;

  private timer: NodeJS.Timeout | undefined;

  private introBgmDelay: Animation | null = null;

  private isOpening: boolean;

  private meloFlg: boolean;

  private isFadeOut: boolean;

  private isChangeRestriction = false;

  private isRetriggerAnimation = false;

  constructor() {
    this.bgmListIndex = undefined;
    this.timer = undefined;
    this.isOpening = false;
    this.meloFlg = false;
    this.isFadeOut = false;

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      if (this.isChangeRestriction) {
        this.playBgm();
      }
    });
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();
      if (this.meloFlg) {
        this.timer = setTimeout(() => {
          AudioApi.fadeOut(3000, bgmList[this.bgmListIndex!].melo!);
        }, 30 * 1000);
      }
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  public setIsFadeOut(status: boolean) {
    this.isFadeOut = status;
  }

  private onModeChange(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode } = settings;
    let bgmTitle: BgmSoundTypes | undefined;

    if (isFreeSpinsMode(mode)) {
      bgmTitle = this.getBonusState(mode);
    } else {
      bgmTitle = BgmSoundTypes.BASE;
      this.isFadeOut = false;
    }

    if (bgmTitle === undefined) {
      this.meloFlg = false;
      this.stopBgm();
    } else if (this.bgmListIndex != bgmTitle) {
      this.meloFlg = false;
      this.stopBgm();
      this.playBgm(bgmTitle);
    }
  }

  public setBaseBgm(): void {
    this.bgmListIndex = BgmSoundTypes.BASE;
  }

  private getBonusState(stage: number): BgmSoundTypes {
    let rtn = BgmSoundTypes.BASE;
    if (stage === 0) {
      rtn = BgmSoundTypes.BASE;
    } else if (stage === 1) {
      rtn = BgmSoundTypes.LA_TOMATINA;
    } else if (stage === 2) {
      rtn = BgmSoundTypes.TOMATO_SPIN_TOMATO;
    } else if (stage === 3) {
      rtn = BgmSoundTypes.TOMATO_SPIN_SPIN;
    }
    return rtn;
  }

  private setBgmIndex(): BgmSoundTypes {
    let bgm: BgmSoundTypes;
    if (isFreeSpinsMode(setGameMode())) {
      if (this.isOpening) {
        bgm = this.getBonusState(setCurrentStage());
        this.isOpening = false;
      } else {
        bgm = this.getBonusState(setCurrentStage() + 1);
      }
    } else {
      bgm = BgmSoundTypes.BASE;
    }
    return bgm;
  }

  public playBgmGameMode(previousGameMode: number): void {
    this.playBgm(this.getBonusState(previousGameMode));
  }

  public playBgm(bgmListIndex?: BgmSoundTypes): void {
    if (setBrokenGame() && bgmListIndex === undefined) {
      return;
    }
    if (AudioApi.isRestricted) {
      return;
    }
    if (bgmListIndex === this.bgmListIndex && bgmListIndex != undefined) {
      return;
    }
    if (bgmListIndex === undefined) {
      const bgmIndex = this.setBgmIndex();
      if (this.bgmListIndex === bgmIndex) {
        return;
      } else {
        this.stopBgm();
        this.bgmListIndex = bgmIndex;
      }
    } else {
      this.stopBgm();
      this.bgmListIndex = bgmListIndex;
    }
    if (this.bgmListIndex != undefined) {
      if (bgmList[this.bgmListIndex].intro === undefined) {
        AudioApi.play({ type: bgmList[this.bgmListIndex].base });
        if (this.isFadeOut) {
          this.fadeOutVolume(0, BGM_FADE_OUT_VOLUME);
        }
      } else {
        this.introBgmDelay = Tween.createDelayAnimation(mappedAudioSprites[bgmList[this.bgmListIndex].intro!].duration);
        this.introBgmDelay.addOnComplete(() => {
          AudioApi.play({
            type: bgmList[this.bgmListIndex!].base,
            stopPrev: true,
          });
        });
        this.introBgmDelay.addOnSkip(() => {
          if (this.bgmListIndex != undefined) {
            AudioApi.stop({ type: bgmList[this.bgmListIndex].intro! });
          }
        });

        AudioApi.play({
          type: bgmList[this.bgmListIndex].intro!,
          stopPrev: true,
        });
        this.introBgmDelay.start();
      }
    }
    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioApi.play({
        type: bgmList[this.bgmListIndex].melo!,
        volume: 0,
      });
    }
  }

  public stopBgm(): void {
    if (this.bgmListIndex != undefined) {
      AudioApi.stop({ type: bgmList[this.bgmListIndex].base });
      if ('melo' in bgmList[this.bgmListIndex!]) {
        AudioApi.stop({
          type: bgmList[this.bgmListIndex!].melo!,
          volume: 0,
        });
      }
      if (bgmList[this.bgmListIndex].intro != undefined && !AudioApi.isPlaying(bgmList[this.bgmListIndex].base)) {
        this.introBgmDelay!.skip();
      }
    }
  }

  public fadeInBase(fadeTime: number): void {
    if (this.isRetriggerAnimation) return;
    if (this.bgmListIndex != undefined) {
      if (bgmList[this.bgmListIndex].intro != undefined && !AudioApi.isPlaying(bgmList[this.bgmListIndex].base)) {
        AudioApi.play({
          type: bgmList[this.bgmListIndex!].base,
          stopPrev: true,
        });
        AudioApi.fadeOut(0, bgmList[this.bgmListIndex].base);
        AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex].base, audioSpriteVolume[bgmList[this.bgmListIndex].base]);
      } else {
        AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex].base, audioSpriteVolume[bgmList[this.bgmListIndex].base]);
      }
    }
  }

  public fadeInMelo(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      if ('melo' in bgmList[this.bgmListIndex]) {
        const soundProp = AudioApi.getSoundByKey(bgmList[this.bgmListIndex].melo!);
        if (soundProp.volume !== 0) {
          return;
        }

        this.meloFlg = true;
        AudioApi.fadeIn(
          fadeTime,
          bgmList[this.bgmListIndex].melo!,
          audioSpriteVolume[bgmList[this.bgmListIndex].melo!],
        );
      }
    }

    this.onSlotMachineStateChange(SlotMachine.getInstance().state);
  }

  public fadeOutAll(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex].base);
      this.fadeOutMelo(fadeTime);

      if (bgmList[this.bgmListIndex].intro != undefined && !AudioApi.isPlaying(bgmList[this.bgmListIndex].base)) {
        this.introBgmDelay!.skip();
      }
    }
  }

  public fadeOutMelo(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      if ('melo' in bgmList[this.bgmListIndex]) {
        this.meloFlg = false;
        AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex].melo!);
      }
    }
  }

  public fadeInVolume(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      this.isFadeOut = false;
      this.fadeOutVolume(0, 0);
      AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex].base, audioSpriteVolume[bgmList[this.bgmListIndex].base]);
    }
  }

  public fadeOutVolume(fadeTime: number, volume: number): void {
    if (this.bgmListIndex != undefined) {
      this.isFadeOut = true;
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex].base, volume);
    }
  }

  public handleChangeRestriction(value = true): void {
    AudioApi.unSuspend();
    AudioApi.processRestriction({
      restricted: false,
    });
    if (setBrokenGame() || !value) {
      this.isChangeRestriction = true;
      eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
    } else {
      this.playBgm(this.getBonusState(setGameMode()));
    }
  }
}

export default new BgmControl();

import {
  buyFeatureBtnTitleDisableStyle,
  buyFeatureBtnTitleStyle,
  buyFeatureMaxLevelTitleDisableStyle,
  buyFeatureMaxLevelTitleStyle,
  totalCostTextAmountDisableStyle,
  totalCostTextAmountStyle,
} from './textStyles';

export enum BtnType {
  laTomatinaBtn,
  TomatoSpinBtn,
}

export const BUY_FEATURE_BTN_TITLE_TEXT_STYLE = [buyFeatureBtnTitleStyle, buyFeatureBtnTitleDisableStyle];
export const BUY_FEATURE_MAX_LEVEL_TEXT_STYLE = [buyFeatureMaxLevelTitleStyle, buyFeatureMaxLevelTitleDisableStyle];
export const BUY_FEATURE_COST_TEXT_STYLE = [totalCostTextAmountStyle, totalCostTextAmountDisableStyle];

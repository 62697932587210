import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { SlotId } from '../../config/config';
import { MAPPED_SYMBOLS } from '../../config/textures';
import { EventTypes } from '../../global.d';
import { setCoinAmount, setCurrency } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { getCombosDataFromIcon } from '../../utils/utils/unique';
import { normalizeCoins, showCurrency } from '../../utils/utils/utils';
import {
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOT_RESOURCE_HEIGHT,
  SLOT_WIDTH,
  eventManager,
  miniPayTableNumberStyle,
  miniPayTableTextStyle,
} from '../config/index';
import { Combos, Icon, IconCombo } from '../d';

class MiniPayTable extends PIXI.Container {
  private id: number;

  private isLast: boolean;

  private currency = '';

  public iconId: SlotId;

  public combos: Combos = [];

  public multipliers: PIXI.Text;

  public multipliersValue: PIXI.Text;

  public slot: PIXI.Sprite;

  constructor(id: number, icon: Icon) {
    super();
    this.id = id;
    this.isLast = id % REELS_AMOUNT === REELS_AMOUNT - 1 || id % REELS_AMOUNT === REELS_AMOUNT - 2;
    this.x = this.isLast ? -REEL_WIDTH : 0;
    this.y = SLOT_RESOURCE_HEIGHT / 2;
    this.iconId = icon.id;
    this.visible = false;

    const bg = this.initBg();
    this.addChild(bg);
    this.slot = this.initSlot();
    this.addChild(this.slot);
    this.multipliers = this.initMultipliers();
    this.addChild(this.multipliers);
    this.multipliersValue = this.initMultipliersValue();
    this.addChild(this.multipliersValue);
    this.currency = setCurrency();
    this.setPayTableData(icon);
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => this.showPayTable(i));
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, this.handleHide);
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.handleHide);
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.handleChangeBetAmount());
  };

  private handleHide = (): void => {
    this.visible = false;
  };

  private initBg = (): PIXI.Sprite => {
    const bg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.minipaytable));
    bg.anchor.set(0, 0.5);
    bg.x = this.isLast ? -98 : -30;
    bg.width = REEL_WIDTH * 2.8;
    bg.height = SLOT_RESOURCE_HEIGHT;
    return bg;
  };

  private initSlot = (): PIXI.Sprite => {
    const slot = new PIXI.Sprite();
    slot.x = this.isLast ? REEL_WIDTH : 0;
    slot.anchor.set(0, 0.5);
    return slot;
  };

  private initMultipliers = (): PIXI.Text => {
    const multipliers = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableNumberStyle,
    });
    multipliers.x = this.isLast ? -40 : 0;
    multipliers.anchor.set(0, 0.5);
    return multipliers;
  };

  private initMultipliersValue = (): PIXI.Text => {
    const multipliersValue = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableTextStyle,
    });
    multipliersValue.x = this.isLast ? 17 : 0;
    multipliersValue.anchor.set(0, 0.5);
    return multipliersValue;
  };

  private calcMultiplier(multiplier: number): number {
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  private getCombosNumbers(): string {
    return this.combos?.reduce((acc: string, curr: IconCombo) => `${acc} ${curr.pattern}: \n`, '') || '';
  }

  private getCombos(): string {
    return (
      this.combos?.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${formatNumber({
            currency: this.currency,
            value: this.calcMultiplier(curr.multiplier),
            showCurrency: showCurrency(this.currency),
          })}\n`,
        '',
      ) || ''
    );
  }

  private handleChangeBetAmount(): void {
    this.multipliersValue.text = this.getCombos();
  }

  public setPayTableData(icon: Icon): void {
    this.iconId = icon.id;
    this.combos = getCombosDataFromIcon(icon);
    this.slot.texture = PIXI.Texture.from(MAPPED_SYMBOLS[icon.id]);
    this.multipliers.text = this.getCombosNumbers();
    this.multipliers.x = this.isLast ? -56 : SLOT_WIDTH;

    this.multipliersValue.text = this.getCombos();
    this.multipliersValue.x = this.multipliers.x + this.multipliers.width + 20;
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!this.combos?.length) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }
}

export default MiniPayTable;

import { useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import { EventTypes, GameMode, IAuthInput, IBonus, IUserBalance, reelSets } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setIsAuthorized,
  setIsEnabledSpaceSpin,
  setIsMobile,
  setIsSoundOn,
  setIsTurboSpin,
  setLatomatina,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  ReelSetType,
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserGql,
  slotHistoryGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  remoteStorage,
  wait,
} from '../../utils';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';
import { checkBrokenGame } from './loadScreenCheckBrokenGame';

const getCoinValue = (slotConfig: ISlotData) => {
  if (setBrokenGame()) {
    return setCurrentBonus().coinValue;
  }
  return slotConfig.clientSettings.coinValues.find((e) => e.code === setCurrency())?.variants[0];
};

const getCoinAmount = (slotConfig: ISlotData) => {
  const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
  let coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slotConfig.clientSettings.coinAmounts.default);

  if (setBrokenGame()) {
    coinAmount = setCurrentBonus().coinAmount;
  }
  return coinAmount;
};

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setUserBalance(userBalance.data.user);
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBrokenGameMain = async () => {
      await checkBrokenGame();
    };

    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lineSet: slot.lineSets[0]!,
        settings: slot.settings,
      });

      // todo add logic to pick gamemode and reelsetid
      setReelSetId(
        slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)?.id || reelSets[GameMode.REGULAR],
      );
      let coinValue = getCoinValue(slot);
      let coinAmount = getCoinAmount(slot);
      if (setBrokenBuyFeature()) {
        coinValue = setCurrentBonus().coinValue;
        coinAmount = setCurrentBonus().coinAmount;
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0]!.coinAmountMultiplier);
    };

    const checkLatomatina = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      let laTomatina = false;
      if (betsData.data.bets.edges[0]?.node.data.features.gameRoundStore.laTomatinaFeatureStore != undefined) {
        laTomatina =
          betsData.data.bets.edges[0]?.node.data.features.gameRoundStore.laTomatinaFeatureStore.sumMultiplier > 1
            ? true
            : false;
      }

      setLatomatina(laTomatina);
    };

    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobile);
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGameMain();
        await getSlotData();
        await checkLatomatina();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [...LOADER_TEXTURES, ...LOADER_SPRITE_TEXTURES, ...SPINE_LOADER_TEXTURES(setIsMobile())],
          process.env.PUBLIC_URL,
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: false,
          isSoundEnabled: isSoundOn,
          onSuspended: () => {},
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(EventTypes.SOUND_INITIALIZED);
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: false,
          });
          setTimeout(() => {
            setShowContent(false);
          }, 500);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
        <div className={styles.companyLogo_loaded} style={{ height: `${progress?.status}%` }}>
          <img draggable="false" alt="logoLoaded" src={Resources.getSource(ResourceTypes.logo)} />
        </div>
      </div>
    </div>
  );
};

export default LoadScreen;

import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class Backdrop2 extends ViewContainer {
  private backdrop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backdrop));

  constructor() {
    super();

    this.addChild(this.backdrop);
    this.backdrop.visible = false;
    this.backdrop.alpha = 0.9;
    this.backdrop.interactive = true;
    this.expandBackdropOverScreen();

    this.backdrop.on('click', () => {
      eventManager.emit(EventTypes.BACK_DROP_CLICK);
    });

    eventManager.on(EventTypes.OPEN_MESSAGE_BANNER, () => {
      this.backdrop.visible = true;
      eventManager.emit(EventTypes.OPEN_POPUP);
    });
    eventManager.on(EventTypes.CLOSE_MESSAGE_BANNER, () => {
      this.backdrop.visible = false;
      eventManager.emit(EventTypes.CLOSE_POPUP);
    });
  }

  // Workaround to position backdrop over view in all landscapes
  public expandBackdropOverScreen(): void {
    this.backdrop.scale.set(4);
    this.x = -(this.backdrop.width / 2);
    this.y = -(this.backdrop.height / 2);
  }
}
export default Backdrop2;

import _ from 'lodash';

import { ReelSet } from '../../global';
import { REELS_AMOUNT } from '../../slotMachine/config';
import { Icon } from '../../slotMachine/d';

import { ById } from './helper';
import { isScatter } from './unique';

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom = random - 1 < 0 ? reelSet.layout[index]!.length - 1 : random - 1;
      return ById(icons, reelSet.layout[index]![prevRandom]!);
    }),
    ...reelPositions.map((random, index) => {
      return ById(icons, reelSet.layout[index]![random]!);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 1) % reelSet.layout[index]!.length;
      return ById(icons, reelSet.layout[index]![nextRandom]!);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 2) % reelSet.layout[index]!.length;
      return ById(icons, reelSet.layout[index]![nextRandom]!);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 3) % reelSet.layout[index]!.length;
      return ById(icons, reelSet.layout[index]![nextRandom]!);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 4) % reelSet.layout[index]!.length;
      return ById(icons, reelSet.layout[index]![nextRandom]!);
    }),
  ];
  return spinResult;
};

export const getScatterCount = (spinResult: Icon[]): number[] => {
  let count = 0;
  return _(spinResult)
    .chunk(REELS_AMOUNT)
    .unzip()
    .map((col) => {
      if (col.some((icon) => isScatter(icon.id))) {
        count += 1;
        return count;
      }
      return 0;
    })
    .value();
};

import * as PIXI from 'pixi.js';

import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';

class Boy extends ViewContainer {
  constructor() {
    super();

    this.init();
  }

  private init() {
    const spine = new SpineAnimation({}, PIXI.Loader.shared.resources['boy']!.spineData);
    spine.addOnStart(() => {
      this.addChild(spine.getSpine());
      spine.setAnimation('idle', true);
    });

    spine.start();
  }
}

export default Boy;

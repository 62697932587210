import { ITextStyle } from 'pixi.js';

export const countDownDisplayTextStyle: Partial<ITextStyle> = {
  fontSize: 240,
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  fill: '#ff1400',
  stroke: '#ffffff',
  fontWeight: 'bold',
  lineHeight: 20,
  strokeThickness: 6,
};

export const countDownAlWaysTextStyle: Partial<ITextStyle> = {
  fontSize: 130,
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  fill: '#ff1400',
  stroke: '#ffffff',
  fontWeight: 'bold',
  lineHeight: 20,
  strokeThickness: 6,
};

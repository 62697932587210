// 予兆振り分け
export const baseGamePhoenix = [25, 100];

// BET金額の200倍以上のPAYOUTの発生するSPIN
export const more200x = [25, 75];

// TOMATO and SPIN FEATUREをトリガーするSPIN
export const tomatoSpinWonTbl = [25, 75];

// Animation selection table
export enum phoenixAnimationType {
  NON = 'No anticipation',
  PHOENIX = 'phoenix',
}

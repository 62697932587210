export enum Colors {
  WHITE_COLOR = 0xffffff,
  // GAME_COLOR = 0xffd653,
  // GAME_COLOR = 0xff0000,
  GAME_COLOR = 0x00ffff,
}

export enum Variables {
  FONT_FAMILY = 'NotoSans-SemiCondensedBold',
}
